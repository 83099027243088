import * as yup from "yup";
import { toast } from "react-toastify";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, DialogActions, Box, Grid, Typography } from "@mui/material";
import UploadImage from "../../../../../components/UploadImage";
import { createKyc, editKyc } from "../../../../../services/userKyc";
import { BACKEND_URL } from "../../../../../utils/constants/apis.urls";
import { userDetails } from "../../../../../redux/slices/userDataSlice";
import { manageDocumentVerifyPayload } from "../../../../../utils/functions/managePayload";

const KycForm = ({ kycData, setKycData, setShowKycSection }) => {
    const userData = useSelector(userDetails);
    const FILE_SIZE = 2 * 1000 * 1000; //2MB Size
    const [frontImage, setFrontImage] = useState(kycData?.front_photo_path ?
        `${kycData?.front_photo_path}` : null);
    const [backImage, setBackImage] = useState(kycData?.back_photo_path ?
        `${kycData?.back_photo_path}` : null);

    const frontPhotoValidation = kycData?.front_photo_path ?
        yup.mixed() :
        yup.mixed()
            .test(
                "required",
                "Document photo is required",
                (value) => {
                    return value.length > 0
                }
            )
            .test("fileSize", "File Size should be less than 2MB", (value) => {
                return value.length && value[0].size <= FILE_SIZE;
            })
    const backPhotoValidation = kycData?.back_photo_path ?
        yup.mixed() :
        yup.mixed().optional()
            .test("fileSize", "File Size should be less than 2MB", (value) => {
                if (value.length > 0) {
                    return value.length && value[0].size <= FILE_SIZE;
                } else {
                    return true;
                }
            })

    const schema = yup.object({
        document_front_photo: frontPhotoValidation,
        document_back_photo: backPhotoValidation
    });

    const { handleSubmit, register, formState: { errors }, reset } = useForm({
        resolver: yupResolver(schema)
    });

    const mySubmit = (data) => {
        if (kycData) {
            const payload = manageDocumentVerifyPayload(data, true);
            editKyc(payload, userData?.id, kycData?.id).then((res) => {
                console.log('----------->', res.data);
                setKycData(res.data.data);
                setShowKycSection(false);
                toast.success("Documents updated", {
                    position: "top-right",
                    theme: "dark"
                });
            }).catch((err) => {
                console.log(err);
                toast.error(Object.values(err.response.data)[0], {
                    position: "top-right",
                    theme: "dark"
                });
            })
        } else {
            const payload = manageDocumentVerifyPayload(data);
            createKyc(payload, userData?.id).then((res) => {
                console.log('----------->', res.data);
                setKycData(res.data.data);
                setShowKycSection(false);
                toast.success("Documents uploaded", {
                    position: "top-right",
                    theme: "dark"
                });
            }).catch((err) => {
                console.log(err);
                toast.error(Object.values(err.response.data)[0][0], {
                    position: "top-right",
                    theme: "dark"
                });
            })
        }
    };

    const handleCancel = () => {
        reset();
        setFrontImage(null);
        setBackImage(null);
    }

    return (
        <>
            <form
                autoComplete="off"
                onSubmit={handleSubmit(mySubmit)}
                style={{ width: '100%' }}
            >
                <Grid container alignItems="center" rowSpacing={3}>
                    <Grid item xs={12} sm={12} md={6} gap={1}
                        sx={{
                            display: 'flex',
                            alignItems: { xs: 'center', md: 'flex-end' },
                            justifyContent: { xs: 'center', md: 'flex-end' },
                            flexDirection: 'column',
                            pr: { xs: 0, md: 5 },
                            pb: 2,
                        }}>
                        {kycData ?
                            <Typography sx={{
                                textAlign: 'center',
                                width: { xs: '80%', sm: '60%', md: '75%', lg: '60%' },
                            }}>Document front photo</Typography> : null
                        }
                        <Box
                            sx={{
                                backgroundColor: frontImage ? '#171717' : 'white',
                                borderRadius: "10px",
                                width: { xs: '80%', sm: '60%', md: '75%', lg: '60%' },
                                height: "11rem",
                                position: "relative",
                                border: errors.document_front_photo ? '1px solid red' : '1px solid lightgrey',
                            }}
                        >
                            <UploadImage fieldName='document_front_photo'
                                label='Upload front photo'
                                register={register} errors={errors}
                                selectedImage={frontImage} setSelectedImage={setFrontImage}
                                hideOptions={kycData?.is_verified ? true : false} />
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} gap={1}
                        sx={{
                            display: 'flex',
                            alignItems: { xs: 'center', md: 'flex-start' },
                            justifyContent: { xs: 'center', md: 'flex-start' },
                            flexDirection: 'column',
                            pl: { xs: 0, md: 5 },
                            pb: 2
                        }}>
                        {kycData ?
                            <Typography sx={{
                                textAlign: 'center',
                                width: { xs: '80%', sm: '60%', md: '75%', lg: '60%' },
                            }}>Document back photo</Typography> : null
                        }
                        <Box
                            sx={{
                                backgroundColor: backImage ? '#171717' : 'white',
                                borderRadius: "10px",
                                width: { xs: '80%', sm: '60%', md: '75%', lg: '60%' },
                                height: "11rem",
                                position: "relative",
                                border: errors.document_back_photo ? '1px solid red' : '1px solid lightgrey',
                            }}
                        >
                            <UploadImage fieldName='document_back_photo'
                                label='Upload back photo'
                                register={register} errors={errors}
                                selectedImage={backImage} setSelectedImage={setBackImage}
                                hideOptions={kycData?.is_verified ? true : false} />
                        </Box>
                    </Grid>
                </Grid>

                {!kycData?.is_verified ?
                    <DialogActions sx={{ pr: 0, mt: 0.5 }}>
                        {!kycData ?
                            <Button color='warning' variant="outlined" sx={{
                                boxShadow: 'none'
                            }} onClick={handleCancel}>
                                Reset
                            </Button>
                            : null
                        }
                        <Button type="submit" color='warning' variant="contained" sx={{
                            boxShadow: 'none'
                        }}>
                            Save
                        </Button>
                    </DialogActions>
                    : null
                }
            </form>
        </>
    )
};

export default KycForm;
