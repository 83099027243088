import { Box, Button, DialogActions, DialogContent, InputAdornment, Stack, TextField, Typography } from "@mui/material";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import CustomRadioGroup from "../../../../../../components/CustomRadioGroup";
import { groupDetails, update_group_details } from "../../../../../../redux/slices/groupDetailSlice";
import { updateGroupDetails } from "../../../../../../services/wGroups/group";
import { manageEditGroupPayload } from "../../../../../../utils/functions/managePayload";

const EditGroupForm = ({ handleClose }) => {
    const dispatch = useDispatch();
    const groupDetailsObj = useSelector(groupDetails);
    const [value, setValue] = React.useState(groupDetailsObj?.is_private ? 'private' : 'public');
    const handleRadioChange = (event) => {
        setValue(event.target.value);
    };

    const {
        handleSubmit,
        register,
        formState: { errors }, watch,
        control, reset
    } = useForm();

    const mySubmit = (data) => {
        data.is_private = +(value === 'private');
        const payload = manageEditGroupPayload(data);
        updateGroupDetails(payload, groupDetailsObj?.id).then((res) => {
            dispatch(update_group_details(res.data.data));
            toast.success("Group details updated successfully!!", {
                position: "top-right",
                theme: "dark"
            });
            handleClose('edit');
        }).catch((err) => {
            console.log("Error ocurred =>", err);
        })
    };

    return (<>
        <form
            autoComplete="off"
            onSubmit={handleSubmit(mySubmit)}
        >
            <DialogContent dividers={true} sx={{ height: { xs: '26rem', md: '25rem' }, overflow: 'auto' }}>
                <Stack sx={{ mx: { xs: 1, sm: 1 } }} spacing={2}>
                    <TextField
                        fullWidth
                        color="warning"
                        label="Group title"
                        defaultValue={groupDetailsObj?.title}
                        error={errors?.title ? true : false}
                        helperText={
                            errors?.title ? 'This is a required field' : null
                        }
                        InputLabelProps={{ shrink: true }}
                        inputProps={{
                            maxLength: 30
                        }}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">
                                <Typography>
                                    {(watch('title') ?? groupDetailsObj?.title).length}/30
                                </Typography>
                            </InputAdornment>
                        }}
                        {...register("title", { required: true })}
                    />

                    <Controller
                        name="since"
                        control={control}
                        defaultValue={new Date(groupDetailsObj?.since, 0, 1)}
                        render={({ field: { onChange, value } }) => (
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <MobileDatePicker
                                    views={['year']}
                                    disableFuture
                                    value={value}
                                    onChange={onChange}
                                    label="Group since"
                                    renderInput={(props) => (
                                        <TextField {...props}
                                            fullWidth
                                            color="warning"
                                            error={errors?.since ? true : false}
                                            helperText={errors?.since ? 'This is required field' : null}
                                            inputProps={{ ...props.inputProps }}
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                        )}
                    />

                    <TextField
                        fullWidth
                        color="warning"
                        label="City"
                        defaultValue={groupDetailsObj?.city}
                        error={errors?.city ? true : false}
                        helperText={
                            errors?.city ? 'This is a required field' : null
                        }
                        InputLabelProps={{ shrink: true }}
                        {...register("city", { required: true })}
                    />
                    <TextField
                        fullWidth
                        color="warning"
                        label="State"
                        defaultValue={groupDetailsObj?.state}
                        error={errors?.state ? true : false}
                        helperText={
                            errors?.state ? 'This is a required field' : null
                        }
                        InputLabelProps={{ shrink: true }}
                        {...register("state", { required: true })}
                    />
                    <TextField
                        fullWidth
                        color="warning"
                        label="Country"
                        defaultValue={groupDetailsObj?.country}
                        error={errors?.country ? true : false}
                        helperText={
                            errors?.country ? 'This is a required field' : null
                        }
                        InputLabelProps={{ shrink: true }}
                        {...register("country", { required: true })}
                    />

                    <Box>
                        <Typography sx={{ mb: 1 }}>Group type</Typography>
                        <CustomRadioGroup value={value} setValue={setValue}
                            handleRadioChange={handleRadioChange} option1='private' option2='public' />
                    </Box>
                </Stack>
            </DialogContent>

            <DialogActions sx={{ mr: { xs: 0, sm: 2 }, my: 0.5 }}>
                <Button variant="outlined" color='warning' onClick={() => reset()}>
                    Reset
                </Button>
                <Button type="submit" color='warning' variant="contained" sx={{
                    boxShadow: 'none'
                }}>
                    Save Changes
                </Button>
            </DialogActions>
        </form>
    </>)
};

export default EditGroupForm;
