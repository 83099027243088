import { yupResolver } from "@hookform/resolvers/yup";
import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Checkbox, Stack, TextField, Typography } from "@mui/material";
import ls from 'local-storage';
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from 'react-redux';
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import { change_user_details } from "../../../../redux/slices/userDataSlice";
import { updateUserDetails } from "../../../../services/Authentication/user";
import { PAGE_PATHS } from "../../../../utils/constants/page-path.constants";
import ImageUpload from "./components/ImageUpload";
import "./register.scss";

const UserDetailsPage = () => {

    const { id } = useParams();
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const FILE_SIZE = 5 * 1000 * 1000; //5MB Size
    const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];

    const [loading, setLoading] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [termsChecked, setTermsChecked] = useState(false);

    const schema = yup.object({
        pic: yup
            .mixed()
            // .test(
            //     "required",
            //     "Profile photo is required",
            //     (value) => value.length > 0
            // ) // For required file type in useForm which is not needed currently
            .test("fileSize", "File Size should not be greater than 5 MB", (value) => {
                if (value.length > 0) {
                    return value[0].size <= FILE_SIZE;
                }
                return true; // No file uploaded, so no size check needed
            })
            .test("fileType", "Only png, jpg and jpeg images supported", (value) => {
                if (value.length > 0) {
                    return SUPPORTED_FORMATS.includes(value[0].type);
                }
                return true; // No file uploaded, so no format check needed
            }),
        firstname: yup.string().required("This field is required"),
        lastname: yup.string().required("This field is required"),
        email: yup.string().email().required("This field is required")
    });

    const {
        handleSubmit,
        register, control,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(schema)
    });

    const onSubmit = (data) => {
        const formData = new FormData();
        formData.append('_method', 'PUT');
        formData.append('first_name', data.firstname);
        formData.append('last_name', data.lastname);
        formData.append('email', data.email);
        if (data.pic.length > 0) {
            formData.append('photo', data.pic[0]);
        }
        if (data.terms) {
            setLoading(true)
            updateUserDetails(formData, { userId: id }).then((res) => {
                console.log('------------>', res.data);
                setLoading(false);
                toast.success("Your profile details updated", {
                    position: "top-right",
                    theme: "dark"
                });
                ls('UserData', res.data.data)
                dispatch(change_user_details(res.data.data))
                navigate(`/${PAGE_PATHS.DASHBOARD}`);
            }).catch((err) => {
                console.log("$$$$$$$$", err);
                setLoading(false);
                setSelectedImage(null);
                toast.error(Object.values(err.response.data)[0][0], {
                    position: "top-right",
                    theme: "dark"
                });
            })
        } else {
            toast.error("Please agree with terms and condition!!", {
                position: "top-right",
                theme: "dark"
            });
        }
    };

    useEffect(() => {
        // if (!state || !state.CountryCode || !state.MobileNumber) {
        //     toast.error("Please verify your mobile number first", {
        //         position: "top-right",
        //         theme: "dark"
        //     });
        //     navigate("/mobile_login");
        // }
    }, []);

    return (
        <>
            <form
                autoComplete="off"
                onSubmit={handleSubmit(onSubmit)}
                className="details-form"
            >
                <ImageUpload register={register} selectedImage={selectedImage} setSelectedImage={setSelectedImage} />
                {errors.pic ? (
                    <Typography
                        sx={{
                            mt: 0.5,
                            color: "#d32f2f",
                            textAlign: "center",
                            fontSize: { xs: '0.8rem', xl: '0.9rem' }
                        }}
                    >
                        {errors.pic.message}
                    </Typography>
                ) : null}

                <Stack
                    sx={{
                        flex: "0.5 1 auto",
                        overflow: "hidden",
                        mx: { xs: "1rem", sm: "auto" },
                        my: { xs: "1rem", sm: "2rem", xl: "1.2rem" },
                        boxShadow: '0 0 15px rgba(0, 0, 0, 0.25)',
                        bgcolor: "white",
                        borderRadius: "1rem",
                        alignItems: "center",
                        justifyContent: "center",
                        px: {
                            xs: "1rem",
                            sm: "4rem",
                            md: "2rem",
                            xl: "calc(5vw)"
                        },
                        py: {
                            xs: "1rem",
                            sm: "1.5rem",
                            md: "1rem"
                        },
                        minWidth: {
                            xs: "16rem",
                            sm: "35rem",
                            md: "25rem",
                            lg: "30rem",
                            xl: "calc(50%)"
                        },
                        gap: {
                            xs: "1rem",
                            sm: "1.25rem",
                            md: "1.25rem",
                            lg: "1.75rem",
                            xl: "calc(4vh)"
                        }
                    }}
                >
                    <TextField
                        fullWidth
                        size="small"
                        color="warning"
                        label="First Name"
                        error={errors.firstname}
                        helperText={
                            errors.firstname ? errors.firstname.message : ""
                        }
                        {...register("firstname")}
                    />

                    <TextField
                        fullWidth
                        size="small"
                        color="warning"
                        label="Last Name"
                        error={errors.lastname}
                        helperText={
                            errors.lastname ? errors.lastname.message : ""
                        }
                        {...register("lastname")}
                    />

                    <TextField
                        fullWidth
                        size="small"
                        color="warning"
                        label="Email Id"
                        error={errors.email}
                        inputProps={{ type: "email" }}
                        helperText={errors.email ? errors.email.message : ""}
                        {...register("email")}
                    />

                    <Stack direction='row' spacing={1} justifyContent='center' alignItems='center'>
                        <Controller
                            name="terms"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <Checkbox color="warning" checked={termsChecked}
                                    onChange={(e) => {
                                        setTermsChecked(e.target.checked);
                                        onChange(e);
                                    }}
                                />)}
                        />
                        <Typography variant="h5"><Link underline="none">T & C</Link> and <Link underline="none">Privacy Policy</Link></Typography>
                    </Stack>

                    {/* <SocialConnect /> */}

                    <Box sx={{
                        width: {
                            xs: "95%",
                            sm: "60%",
                            md: "70%",
                            lg: "60%",
                            xl: "75%"
                        }
                    }} className="details-submit-btn">
                        <LoadingButton
                            fullWidth
                            type="submit"
                            loading={loading}
                            variant="contained"
                            startIcon={loading ? <SaveIcon /> : ''}
                            loadingPosition={loading ? "start" : null}
                            sx={{ bgcolor: "#1F274A", color: "white" }}
                        >
                            Continue
                        </LoadingButton>
                    </Box>
                </Stack>
            </form>
        </>
    );
};

export default UserDetailsPage;
