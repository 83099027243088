import { Box, Button, DialogActions, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import CustomRadioGroup from "../../../../../../../../../components/CustomRadioGroup";
import DialogBox from "../../../../../../../../../components/DialogBox";
import RenderFormFields from "../../../../../../../../../components/RenderFormFields";
import { groupDetails } from "../../../../../../../../../redux/slices/groupDetailSlice";
import { createGroupAlbum } from "../../../../../../../../../services/wGroups/groupDetails";
import { createGroupAlbumForm } from "../../../../../../../../../utils/constants/createGroupAlbum";
import { albumFieldsToHide } from "../../../../../../../../../utils/constants/eventAlbumHideFields";
import { manageEventAlbumPayload } from "../../../../../../../../../utils/functions/managePayload";

const CreateAlbumContent = (handleClose, setItems) => {
    const {
        handleSubmit,
        register,
        formState: { errors },
        control, reset
    } = useForm();

    const groupData = useSelector(groupDetails)
    const [albumType, setAlbumType] = useState('photos');
    const handleRadioChange = (event) => {
        setAlbumType(event.target.value);
    };

    const mySubmit = (data) => {
        data.type = albumType;
        if (albumType === 'link') {
            const spaces = data.album_link.split(' ').length;
            if (spaces > 1) {
                toast.error("Online platform link should not contain spaces", {
                    position: "top-right",
                    theme: "dark"
                });
                return;
            }
        }
        let payload = manageEventAlbumPayload(data);

        createGroupAlbum(payload, groupData?.id).then((res) => {
            toast.success("Album Created Successfully!!", {
                position: "top-right",
                theme: "dark"
            });
            setItems((items) => [...items, res.data.data])
            handleClose();
            reset();
        }).catch((err) => {
            console.log(err);
            toast.error(Object.values(err.response.data)[0][0], {
                position: "top-right",
                theme: "dark"
            });
        })
    };

    useEffect(() => {
        return () => {
            reset()
        };
    }, [handleClose]);

    return (
        <>
            <form
                autoComplete="off"
                onSubmit={handleSubmit(mySubmit)}
                style={{ marginTop: '1rem' }}
            >
                <Stack spacing={2}>
                    <Box>
                        <Typography sx={{ mb: 1 }}>Album Type</Typography>
                        <CustomRadioGroup value={albumType} setValue={setAlbumType}
                            handleRadioChange={handleRadioChange}
                            option1='photos' option2='link' />
                    </Box>
                    {createGroupAlbumForm.data.map((item, index) => (
                        !((albumType === 'photos' && albumFieldsToHide.linkFields.includes(item.name)) ||
                            (albumType === 'link' && albumFieldsToHide.photosFields.includes(item.name))
                        ) ? // Link not needed for photos type & Switches not needed for link type
                            <RenderFormFields key={index} item={item}
                                register={register}
                                errors={errors} control={control}
                            />
                            : null
                    ))}
                </Stack>

                <DialogActions sx={{ my: 1, pr: 0, pt: 1 }}>
                    <Button type="submit" color='warning' variant="contained" sx={{
                        boxShadow: 'none'
                    }}>
                        Create
                    </Button>
                </DialogActions>
            </form>
        </>
    )
}

const CreateGalleryDialog = ({ handleClose, setItems }) => {
    return (
        <DialogBox open={true} handleClose={handleClose}
            title='Add Album' content={CreateAlbumContent(handleClose, setItems)}
        />
    )
};

export default CreateGalleryDialog;
