import SaveIcon from '@mui/icons-material/Save';
import VerifiedIcon from '@mui/icons-material/Verified';
import { LoadingButton } from "@mui/lab";
import { Chip } from "@mui/material";
import React from "react";

const QRTicketsPayment = ({ loading, paymentDone, type }) => {
    return (
        <>
            {paymentDone ?
                <Chip
                    label="Paid" color='success' icon={<VerifiedIcon />}
                />
                :
                <LoadingButton
                    type="submit"
                    loading={loading}
                    startIcon={loading ? <SaveIcon /> : ''}
                    loadingPosition={loading ? "start" : null}
                    sx={{
                        color: 'white',
                        backgroundColor: loading ? 'lightgray' : '#1F274A',
                        ":hover": {
                            backgroundColor: '#1F274A',
                        },
                    }}
                >
                    {loading ? 'Processing..' : `Generate, Pay & ${type}`}
                </LoadingButton>
            }
        </>
    )
};

export default QRTicketsPayment;
