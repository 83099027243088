import { Box, Button, DialogActions, Stack, Typography } from "@mui/material";
import pluralize from "pluralize";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { EventAlbumsContext } from "..";
import CustomRadioGroup from "../../../../../../../../../components/CustomRadioGroup";
import DialogBox from "../../../../../../../../../components/DialogBox";
import RenderFormFields from "../../../../../../../../../components/RenderFormFields";
import { eventDetails } from "../../../../../../../../../redux/slices/eventDataSlice";
import { editEventAlbum } from "../../../../../../../../../services/wEvents/eventDetails";
import { editAlbumForm } from "../../../../../../../../../utils/constants/editEventAlbum";
import { albumFieldsToHide } from "../../../../../../../../../utils/constants/eventAlbumHideFields";
import { manageEventAlbumPayload } from "../../../../../../../../../utils/functions/managePayload";

const EditAlbumContent = (handleClose, setItems, albumDetails) => {
    const {
        handleSubmit,
        register,
        formState: { errors },
        control, reset
    } = useForm();

    const eventData = useSelector(eventDetails)
    const { title } = useContext(EventAlbumsContext);

    const [albumType, setAlbumType] = useState(albumDetails.type);
    const handleRadioChange = (event) => {
        setAlbumType(event.target.value);
    };

    const mySubmit = (data) => {
        data.type = albumType;
        let payload = manageEventAlbumPayload(data, true);
        if (albumType === 'link') {
            const spaces = data.album_link.split(' ').length;
            if (spaces > 1) {
                toast.error("Online platform link should not contain spaces", {
                    position: "top-right",
                    theme: "dark"
                });
                return;
            }
        }

        editEventAlbum(payload, eventData?.id, albumDetails?.id).then((res) => {
            console.log('---------------->', res.data);
            toast.success(`${pluralize.singular(title)} Updated Successfully!!`, {
                position: "top-right",
                theme: "dark"
            });
            setItems((items) => {
                let ele = items.find(i => i.id === res.data?.data?.id);
                items[items.indexOf(ele)] = res.data.data;
                return [...items];
            })
            handleClose();
        }).catch((err) => {
            console.log(err);
            toast.error(Object.values(err.response.data)[0][0], {
                position: "top-right",
                theme: "dark"
            });
        })
    };

    useEffect(() => {
        return () => {
            reset()
        };
    }, [handleClose]);

    return (
        <>
            <form
                autoComplete="off"
                onSubmit={handleSubmit(mySubmit)}
                style={{ marginTop: '1rem' }}
            >
                <Stack spacing={2}>
                    <Box>
                        <Typography sx={{ mb: 1 }}>Album Type</Typography>
                        <CustomRadioGroup value={albumType} setValue={setAlbumType}
                            handleRadioChange={handleRadioChange}
                            option1='photos' option2='link' />
                    </Box>
                    {editAlbumForm.data.map((item, index) => (
                        !((albumType === 'photos' && albumFieldsToHide.linkFields.includes(item.name)) ||
                            (albumType === 'link' && albumFieldsToHide.photosFields.includes(item.name))
                        ) ?
                            <RenderFormFields key={index} item={item} eventData={eventData} register={register} errors={errors} control={control}
                                editForm={true} cardDetails={albumDetails} />
                            : null
                    ))}
                </Stack>

                <DialogActions sx={{ my: 1, pr: 0, pt: 1 }}>
                    <Button type="submit" color='warning' variant="contained" sx={{
                        boxShadow: 'none'
                    }}>
                        Save Changes
                    </Button>
                </DialogActions>
            </form>
        </>
    )
}

const EditAlbumDialog = ({ open, handleClose, setItems, albumDetails }) => {
    return (
        <DialogBox open={open} handleClose={handleClose}
            title='Edit Album' content={EditAlbumContent(handleClose, setItems, albumDetails)}
        />
    )
};

export default EditAlbumDialog;
