import { Box, Button, DialogActions, Stack, Typography } from "@mui/material";
import pluralize from "pluralize";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { EventAlbumsContext } from "..";
import DialogBox from "../../../../../../../../../components/DialogBox";
import RenderFormFields from "../../../../../../../../../components/RenderFormFields";
import { eventDetails } from "../../../../../../../../../redux/slices/eventDataSlice";
import { createEventAlbum } from "../../../../../../../../../services/wEvents/eventDetails";
import { createEventAlbumForm } from "../../../../../../../../../utils/constants/createEventAlbum";
import { manageEventAlbumPayload } from "../../../../../../../../../utils/functions/managePayload";
import CustomRadioGroup from "../../../../../../../../../components/CustomRadioGroup";
import { albumFieldsToHide } from "../../../../../../../../../utils/constants/eventAlbumHideFields";

const CreateAlbumContent = (handleClose, setItems) => {
    const {
        handleSubmit,
        register,
        formState: { errors },
        control, reset
    } = useForm();

    const eventData = useSelector(eventDetails)
    const { viewId, title } = useContext(EventAlbumsContext);

    const [albumType, setAlbumType] = useState('photos');
    const handleRadioChange = (event) => {
        setAlbumType(event.target.value);
    };

    const mySubmit = (data) => {
        data.viewId = viewId;
        data.type = albumType;
        if (albumType === 'link') {
            const spaces = data.album_link.split(' ').length;
            if (spaces > 1) {
                toast.error("Online platform link should not contain spaces", {
                    position: "top-right",
                    theme: "dark"
                });
                return;
            }
        }
        let payload = manageEventAlbumPayload(data);

        createEventAlbum(payload, eventData?.id).then((res) => {
            toast.success(`${pluralize.singular(title)} Created Successfully!!`, {
                position: "top-right",
                theme: "dark"
            });
            setItems((items) => [...items, res.data.data])
            handleClose();
            reset();
        }).catch((err) => {
            console.log(err);
            toast.error(Object.values(err.response.data)[0][0], {
                position: "top-right",
                theme: "dark"
            });
        })
    };

    useEffect(() => {
        return () => {
            reset()
        };
    }, [handleClose]);

    return (
        <>
            <form
                autoComplete="off"
                onSubmit={handleSubmit(mySubmit)}
                style={{ marginTop: '1rem' }}
            >
                <Stack spacing={2}>
                    <Box>
                        <Typography sx={{ mb: 1 }}>Album Type</Typography>
                        <CustomRadioGroup value={albumType} setValue={setAlbumType}
                            handleRadioChange={handleRadioChange}
                            option1='photos' option2='link' />
                    </Box>
                    {createEventAlbumForm.data.map((item, index) => (
                        !((albumType === 'photos' && albumFieldsToHide.linkFields.includes(item.name)) ||
                            (albumType === 'link' && albumFieldsToHide.photosFields.includes(item.name))
                        ) ? // Link not needed for photos type & Switches not needed for link type
                            <RenderFormFields key={index} item={item}
                                eventData={eventData} register={register}
                                errors={errors} control={control}
                            />
                            : null
                    ))}
                </Stack>

                <DialogActions sx={{ my: 1, pr: 0, pt: 1 }}>
                    <Button type="submit" color='warning' variant="contained" sx={{
                        boxShadow: 'none'
                    }}>
                        Create
                    </Button>
                </DialogActions>
            </form>
        </>
    )
}

const CreateAlbumDialog = ({ title, handleClose, setItems }) => {
    return (
        <DialogBox open handleClose={handleClose}
            title={`Add ${pluralize.singular(title)}`}
            content={CreateAlbumContent(handleClose, setItems)}
        />
    )
};

export default CreateAlbumDialog;
