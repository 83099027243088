import { Button, DialogActions, Stack, Typography } from "@mui/material";
import pluralize from "pluralize";
import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { EventKeyMembersContext } from "..";
import DialogBox from "../../../../../../../../../components/DialogBox";
import { eventDetails } from "../../../../../../../../../redux/slices/eventDataSlice";
import { deleteEventMember } from "../../../../../../../../../services/wEvents/eventDetails";

const DeleteMemberContent = (handleClose, setItems, memberDetails) => {
    const {
        handleSubmit,
    } = useForm();
    const eventData = useSelector(eventDetails);
    const { title } = useContext(EventKeyMembersContext);

    const mySubmit = () => {
        deleteEventMember(eventData?.id, memberDetails?.id).then(() => {
            setItems((items) => {
                let newItems = items.filter((i) => i.id !== memberDetails?.id)
                return newItems;
            })
            toast.success(`${pluralize.singular(title)} Deleted!!`, {
                position: "top-right",
                theme: "dark"
            });
            handleClose();
        }).catch((err) => {
            console.log(err);
        })
    };

    return (
        <>
            <form onSubmit={handleSubmit(mySubmit)} >
                <Stack spacing={3}>
                    <Typography>Are you sure you want to delete this person details ?</Typography>
                    <DialogActions sx={{ my: 1, pr: 0, pt: 1 }}>
                        <Button variant="outlined" color='warning' onClick={handleClose}>
                            Cancel
                        </Button>
                        <Button type="submit" color='warning' variant="contained" sx={{
                            boxShadow: 'none'
                        }}>
                            Ok
                        </Button>
                    </DialogActions>
                </Stack>
            </form>
        </>
    )
}

const DeleteMemberDialog = ({ handleClose, setItems, memberDetails }) => {
    return (
        <DialogBox open={true} handleClose={handleClose}
            title='Delete Person' content={DeleteMemberContent(handleClose, setItems, memberDetails)}
        />
    )
};

export default DeleteMemberDialog;
