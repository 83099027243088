import dayjs from "dayjs";
import _ from "lodash";
import { convertLocalToUtc } from "./timeConversion";
import { getExtraChargesCosting } from "./getExtraChargesCosting";

export const manageDocumentVerifyPayload = (data, editKyc = false) => {
    const payload = new FormData();
    editKyc && payload.append("_method", "PUT");

    data["document_front_photo"].length > 0 &&
    payload.append(
        "document_front_photo",
        Array.from(data["document_front_photo"])[0]
    );
    data["document_back_photo"].length > 0 &&
    payload.append(
        "document_back_photo",
        Array.from(data["document_back_photo"])[0]
    );
    return payload;
};

export const manageBankPayload = (data, editBank = false) => {
    const payload = new FormData();
    editBank && payload.append("_method", "PUT");

    payload.append("bank_name", data["bank_name"]);
    payload.append("ifsc_code", data["ifsc_code"]);
    payload.append("account_number", data["account_number"]);
    payload.append("account_holder_name", data["account_holder_name"]);
    payload.append("account_holder_email", data["account_holder_email"]);
    data["bank_cheque_photo"].length > 0 &&
    payload.append(
        "bank_cheque_photo",
        Array.from(data["bank_cheque_photo"])[0]
    );

    return payload;
};

export const manageCreateEventPayload = (formData, groupId) => {
    let payload = { ...formData };
    if (groupId) {
        payload.group_id = groupId;
    }
    if (formData.start_date) {
        payload["start_date"] = dayjs(formData.start_date).format("YYYY-MM-DD");
        payload["start_time"] = dayjs(formData.start_date).format("HH:mm:ss");
    }
    if (formData.end_date) {
        payload["end_date"] = dayjs(formData.end_date).format("YYYY-MM-DD");
        payload["end_time"] = dayjs(formData.end_date).format("HH:mm:ss");
    }
    if (formData.is_online) {
        payload["event_link"] = formData.eventLink;
    } else {
        payload["address"] = (
            formData.eventVenue.venueName +
            " " +
            formData.eventVenue.streetLine +
            " " +
            formData.eventVenue.landmark
        ).trim();
        payload["zip"] = formData.eventVenue.zipCode;
        payload["city"] = formData.eventVenue.city;
        payload["state"] = formData.eventVenue.state;
        payload["country"] = formData.eventVenue.country;
        payload["google_map_link"] = formData.eventVenue.googleMapLink;
        payload["latitude"] = 0;
        payload["longitude"] = 0;
    }
    payload["is_online"] = +formData.is_online;
    payload["is_private"] = +formData.is_private;
    payload["is_paid"] = +formData.is_paid;
    payload["has_registration"] = +formData.has_registration;
    payload["is_qrcode"] = +formData.is_qrcode;
    payload["allow_create_feed"] = +formData.allow_create_feed;
    payload["multiple_venues"] = +formData.multiple_venues;
    payload = { ...payload, description: "", welcome_note: "", barcolor: "" };
    ["category", "eventVenue", "eventLink","current_tab_value"].forEach((e) => delete payload[e]);;
    return payload;
};

export const manageEditEventPayload = (formData, is_online, isMultipleVenue) => {
    let payload = { ...formData };
    payload["_method"] = "PUT";
    payload["multiple_venues"] = isMultipleVenue;
    payload["timezone"] = formData.timezone?.value;
    // flag to check if backend validation is required
    payload["event_details_validation"] = true;
    if (formData.start_date) {
        payload["start_date"] = dayjs(formData.start_date).format("YYYY-MM-DD");
        payload["start_time"] = dayjs(formData.start_date).format("HH:mm:ss");
    }
    if (formData.end_date) {
        payload["end_date"] = dayjs(formData.end_date).format("YYYY-MM-DD");
        payload["end_time"] = dayjs(formData.end_date).format("HH:mm:ss");
    }

    if (is_online) {
        payload["is_online"] = 1;
        payload["event_link"] = formData.eventLink;

        // Set offline event parameters to null for online events
        [
            "address",
            "city",
            "zip",
            "state",
            "country",
            "google_map_link"
        ].forEach((e) => {
            payload[e] = null;
        });
    } else {
        // All address parameter are already in form data
        // Also set online event parameters to null for offline events
        payload["is_online"] = 0;
        payload["event_link"] = null;

        payload["latitude"] = 0;
        payload["longitude"] = 0;
    }

    ["category", "eventVenue", "eventLink"].forEach((e) => delete payload[e]);

    return payload;
};

export const manageEventSettingsPayload = (data, eventDetailsObj) => {
    let payload = { ...data };
    payload["_method"] = "PUT";
    payload["eventId"] = eventDetailsObj?.id;
    if (payload["bank"]) {
        payload["bank_id"] = payload["bank"].id;
    }
    delete payload["bank"];
    // payload['is_qrcode'] = +payload['is_qrcode']
    // payload['allow_create_feed'] = +payload['allow_create_feed']
    return payload;
};

export const manageEventFormPayload = (data) => {
    const { formTitle, ...fields } = data;
    Object.values(fields).map((f) => (f.mandatory = +f.mandatory));
    // console.log(Object.values(fields));
    let payload = {};
    payload["form_title"] = formTitle;
    payload["fields"] = Object.values(fields);
    return payload;
};

export const manageEventProgramPayload = (
    formData,
    eventData,
    editProgram = false,
    mediaId
) => {
    // mediaId is for delete old image if author wants to remove image
    let payload = new FormData();
    editProgram && payload.append("_method", "PUT");
    if (!editProgram) {
        payload.append("model_view_id", formData.viewId);
    }
    payload.append("title", formData.title);
    payload.append(
        "start_datetime",
        dayjs(formData.start_datetime).format("YYYY-MM-DD HH:mm:ss")
    );
    dayjs(formData["end_datetime"]).isValid() &&
    payload.append(
        "end_datetime",
        dayjs(formData.end_datetime).format("YYYY-MM-DD HH:mm:ss")
    );
    payload.append(
        "timezone",
        typeof formData.timezone === "object"
            ? formData.timezone.value
            : "Asia/Kolkata"
    );

    const requestFile = formData["file"];
    mediaId && payload.append("delete_files[]", mediaId);
    if (requestFile instanceof File) {
        requestFile && payload.append("file[]", requestFile);
    } else if (requestFile instanceof FileList) {
        const files = requestFile ? Array.from(requestFile) : null;
        if (files) {
            //Either [] or [{}]
            if (files.length !== 0) {
                files.forEach((file) => {
                    payload.append("file[]", file);
                });
            }
        }
    }

    formData["description"] &&
    payload.append("description", formData["description"]);
    formData["instructions"] &&
    payload.append("instructions", formData["instructions"]);
    formData["dress_code"] &&
    payload.append("dress_code", formData["dress_code"]);
    formData["theme_color"] &&
    payload.append("theme_color", formData["theme_color"]);

    if (eventData.is_online) {
        payload.append("is_online", 1);
        payload.append("live_url", formData.live_url);
    } else {
        payload.append("is_online", 0);
        payload.append("venue[name]", _.get(formData, "venue[name]"));
        _.get(formData, "venue[address]")
            ? payload.append(
                "venue[address]",
                _.get(formData, "venue[address]")
            )
            : payload.append("venue[address]", eventData.address);
        _.get(formData, "venue[city]")
            ? payload.append("venue[city]", _.get(formData, "venue[city]"))
            : payload.append("venue[city]", eventData.city);
        _.get(formData, "venue[state]")
            ? payload.append("venue[state]", _.get(formData, "venue[state]"))
            : payload.append("venue[state]", eventData.state);
        _.get(formData, "venue[zip]")
            ? payload.append("venue[zip]", _.get(formData, "venue[zip]"))
            : payload.append("venue[zip]", eventData.zip);
        _.get(formData, "venue[country]")
            ? payload.append(
                "venue[country]",
                _.get(formData, "venue[country]")
            )
            : payload.append("venue[country]", eventData.country);
        _.get(formData, "venue[landmark]") &&
        payload.append(
            "venue[landmark]",
            _.get(formData, "venue[landmark]")
        );
        _.get(formData, "venue[google_map_link]") &&
        payload.append(
            "venue[google_map_link]",
            _.get(formData, "venue[google_map_link]")
        );
        payload.append("venue[latitude]", 0);
        payload.append("venue[longitude]", 0);
    }
    return payload;
};

export const manageEventMemberPayload = (formData, editMember = false) => {
    let payload = new FormData();
    editMember && payload.append("_method", "PUT");
    if (!editMember) {
        payload.append("model_view_id", formData.viewId);
    }

    payload.append("type", formData.type);
    payload.append("title", formData.title);
    payload.append(
        "show_default_user_avatar",
        +formData.show_default_user_avatar
    );

    const files = formData["photo"] ? Array.from(formData["photo"]) : null;
    if (files) {
        if (files.length === 0) {
            payload.append("delete_photo", 1);
        } else {
            files.forEach((file) => {
                payload.append("photo", file);
            });
        }
    }

    const optional_fields = [
        "relation",
        "designation",
        "description",
        "address"
    ];
    optional_fields.forEach((field) => {
        formData[field] && payload.append(field, formData[field]);
    });
    formData.contacts["personal"] &&
    payload.append("contacts[personal]", formData.contacts["personal"]);
    formData["socials"] &&
    payload.append(
        `socials[${formData.socialMediaType}]`,
        formData["socials"]
    );

    return payload;
};

export const manageEventAlbumPayload = (formData, editAlbum = false) => {
    // No need of form data in this payload
    let payload = { ...formData };
    editAlbum && Object.assign(payload, { _method: "PUT" });
    if (!editAlbum) {
        Object.assign(payload, { "model_view_id": formData.viewId });
        delete payload["viewId"];
    }
    if (formData.type === 'photos') {
        payload["allow_download"] = formData["allow_download"] ? 1 : 0;
        payload["allow_guest_upload"] = formData["allow_guest_upload"] ? 1 : 0;
        payload["is_private"] = formData["is_private"] ? 1 : 0;
        payload["album_link"] = null;
    } else {
        payload = _.omit(payload, ["allow_download", "allow_guest_upload", "is_private"]);
    }

    return payload;
};

export const manageAlbumMediaPayload = (files) => {
    let formdata = new FormData();
    const fileList = files ? Array.from(files) : null;
    if (fileList.length !== 0) {
        fileList.forEach((file) => {
            formdata.append("file[]", file);
        });
        formdata.append("media_type", "image");
    }
    return formdata;
};

export const manageEventRsvpPayload = (data) => {
    const { welcome_note, ...fields } = data;
    Object.values(fields).map((f) => (f.mandatory = +f.mandatory));
    let payload = {};
    payload["fields"] = Object.values(fields);
    return payload;
};

export const manageEventGuestPayload = (formData, editEventGuest = false) => {
    let payload = new FormData();

    if (editEventGuest) {
        // Check if there are extra guest details other than name and email
        const extraKeys = Object.keys(formData).filter(key => !['guest_name', 'email'].includes(key));
        if (extraKeys.length > 0) {
            payload.extra_guest_details = {};
            extraKeys.forEach(key => {
                payload.extra_guest_details[key] = formData[key];
                delete formData[key]; // Delete from formData, not payload
            });
        }
        payload.append('_method', 'PUT');
    }

    // Add guest details to payload
    if(formData.guest_name){
        payload.append('guest_name', formData.guest_name);
    }
    if(formData.email)
    {     
        payload.append('email', formData.email);
    }
    if(formData.dialing_code){

        payload.append('dialing_code', formData.dialing_code);
    }
    if (formData.mobile) {     
        payload.append('mobile', formData.mobile);
    }

    const files = formData["guest_photo"] ? Array.from(formData["guest_photo"]) : null;
    if (files) {
            files.forEach((file) => {
                payload.append(`guest_photo`, file);
            });
    }

    return payload;
};


export const manageSubstituteUserPayload = (eventUserId, guestId, ticketsCount) => {
    let payload = {};
    payload.substitute_user_id = eventUserId;
    payload.invited_by = guestId;
    payload.tickets_count = ticketsCount;
    return payload;
}

export const manageBulkDeleteEventGuestsPayload = (guestIds) => {
    const formData = new FormData();
    guestIds.forEach((g) => {
        formData.append("guest_ids[]", g);
    });
    return formData;
};

export const manageInviteMessagePayload = (
    inviteFields,
    guestIds,
    data,
    model_type
) => {
    let formData = new FormData();
    formData.append("model_type", model_type);

    if (model_type === "event") {
        formData.append("amount_paid", data["amount_paid"]);
        formData.append("currency", data["currency"]);
        formData.append("gst_charges", data["gst_charges"]);
        formData.append("extra_charges", data["extra_charges"]);
        formData.append("total_guests", data["total_guests"]);
    }
    formData.append("total_sms", data["total_sms"] ?? 0);

    inviteFields.forEach((f) => {
        if (f[0] === "email") {
            formData.append("email", data[`emailMessage`]);
            formData.append(
                "candidates_to_email",
                model_type === "event"
                    ? data["guests_for_email"]
                    : data["members_for_email"]
            );
        } else if (f[0] === "whatsapp" || f[0] === "sms") {
            if (model_type === "event") {
                formData.append(
                    `${f[0]}[invitation_event_name]`,
                    Object.values(data[`${f[0]}MessageFields`])[0]
                );
            } else if (model_type === "group") {
                formData.append(
                    `${f[0]}[invitation_group_name]`,
                    Object.values(data[`${f[0]}MessageFields`])[0]
                );
            }
            formData.append(
                `${f[0]}[regards]`,
                Object.values(data[`${f[0]}MessageFields`])[1] ?? ""
            );
        }
    });
    if (guestIds.length > 0) {
        guestIds.forEach((g, i) => {
            formData.append(`guest_ids[${i}]`, g);
        });
    } else {
        formData.append(`guest_ids`, []);
    }
    var utc_time = "";
    var local_time = data["scheduled_date"];
    if (local_time) {
        utc_time = convertLocalToUtc(local_time);
    }
    formData.append("scheduled_date", utc_time);
    return formData;
};

export const manageCreateGroupPayload = (data) => {
    const formData = new FormData();
    for (const element in data) {
        formData.append(element, data[element]);
    }
    formData.set("since", dayjs(data["since"]).year());
    formData.set("is_private", +data["is_private"]);
    if (!data["group_logo"]) {
        formData.delete("group_logo");
    }
    return formData;
};

export const manageEditGroupPayload = (data) => {
    const formData = new FormData();
    formData.append("_method", "PUT");
    for (const element in data) {
        formData.append(element, data[element]);
    }
    data["since"] && formData.set("since", dayjs(data["since"]).year());
    data["is_private"] && formData.set("is_private", +data["is_private"]);
    if (data["bank"]) {
        formData.append("bank_id", data["bank"].id);
    }
    formData.delete("bank");
    return formData;
};

export const manageGroupKeyPersonPayload = (formData, editPerson = false) => {
    let payload = new FormData();
    editPerson && payload.append("_method", "PUT");

    payload.append("type", formData.type);
    payload.append("title", formData.title);
    payload.append(
        "show_default_user_avatar",
        +formData.show_default_user_avatar
    );

    const files = formData["photo"] ? Array.from(formData["photo"]) : null;
    if (files) {
        if (editPerson && files.length === 0) {
            payload.append("delete_photo", 1);
        } else {
            files.forEach((file) => {
                payload.append("person_photo", file);
            });
        }
    } else {
        payload.append("person_photo", null);
    }

    const optional_fields = [
        "relation",
        "designation",
        "description",
        "address"
    ];
    optional_fields.forEach((field) => {
        formData[field] && payload.append(field, formData[field]);
    });
    formData.contacts["personal"] &&
    payload.append("contacts[personal]", formData.contacts["personal"]);
    formData["socials"] &&
    payload.append(
        `socials[${formData.socialMediaType}]`,
        formData["socials"]
    );

    return payload;
};

export const manageGroupAlbumPayload = (formData, editAlbum = false) => {
    // No need of form data in this payload

    let payload = { ...formData };
    editAlbum && Object.assign(payload, { _method: "PUT" });

    if (formData.type === 'photos') {
        payload["allow_download"] = formData["allow_download"] ? 1 : 0;
        payload["allow_guest_upload"] = formData["allow_guest_upload"] ? 1 : 0;
        payload["is_private"] = formData["is_private"] ? 1 : 0;
        payload["album_link"] = null;
    } else {
        payload = _.omit(payload, ["allow_download", "allow_guest_upload", "is_private"]);
    }

    return payload;
};

export const manageBulkDeleteGroupMembersPayload = (memberIds) => {
    // console.log(guestIds)
    const formData = new FormData();
    memberIds.forEach((g) => {
        formData.append("member_ids[]", g);
    });
    return formData;
};

export const manageRsvpAnswerPayload = (data) => {
    let formData = new FormData();
    Object.keys(data)?.forEach((key, index) => {
        formData.append(`QAs[${index}][question_id]`, key);
        formData.append(`QAs[${index}][answer]`, data[key]);
    });
    return formData;
};

export const manageModelFormAnswerPayload = (data, { member_uuid }) => {
    let formData = new FormData();
    // as we don't need this key in QAs format

    Object.keys(data).forEach((key, index) => {
        formData.append(`QAs[${index}][question_id]`, key);
        if (data[key] instanceof FileList) { // for image need to store all data in array
            const files = Array.from(data[key]);
            if (files.length === 0) {
                formData.append(`QAs[${index}][answer]`, []);
            } else {
                files.forEach((file) => {
                    formData.append(`QAs[${index}][answer]`, file);
                });
            }
        } else if (typeof data[key] === "object") {
            // For checkbox need to store all options in string of array format (e.g. "[1, 2, 3]")
            formData.append(`QAs[${index}][answer]`, JSON.stringify(data[key]));
        } else {
            formData.append(`QAs[${index}][answer]`, data[key] ?? "");
        }
    });
    if (member_uuid) {
        formData.append("member_uuid", member_uuid);
    }
    return formData;
};

export const manageSubscriptionSelectPayload = (data, uuid) => {
    let payload = {};
    payload.subscription_id = data.selected_subscription;
    payload.member_uuid = uuid;
    return payload;
};

export const manageTicketSelectPayload = (ticketPayload, facilitiesSelected, uuid, eventTicketType, activeUserId, isWhatsappChecked) => {
    let payload = {};

    // Add selected tickets array (which contains ticket_id, ticket_count, and ticket_price)
    payload.selected_tickets = ticketPayload.selected_tickets;

    // Calculate total ticket amount based on the selected tickets
    payload.total_amount = ticketPayload.total_amount_to_pay;

    // Other information like currency, UUID, and WhatsApp preferences
    payload.amount_currency = ticketPayload.amount_currency;
    payload.guest_uuid = uuid;
    payload.send_to_whatsapp = (eventTicketType === 'paid' && ticketPayload.total_amount_to_pay > 0) ? 0 : isWhatsappChecked;
    payload.registered_by = activeUserId;

    // Handle facility details (if any facilities are selected)
    if (ticketPayload.total_amount_to_pay === 0) {
        const selectedFacilities = facilitiesSelected.length > 0 ?
            facilitiesSelected.filter(facility => facility.selected).map(facility => facility.name) : [];
        payload.facility_details = selectedFacilities.length > 0 ? JSON.stringify(selectedFacilities) : null;
    }

    return payload;
};

export const manageSubstituteTicketPayload = (formData, ticketsCount, uuid, guestId, substituteUserId, invitedById) => {
    let payload = { ...formData };
    delete payload["email"];
    delete payload["guest_name"];
    payload.tickets_count = ticketsCount;
    payload.guest_uuid = uuid;
    payload.guest_id = guestId;
    payload.substitute_user_id = substituteUserId;
    payload.invited_by = invitedById;
    return payload;
}

export const manageEventTickerPayload = (data, isEdit = false, eventTicketType, facilityDetails) => {
    let ticketPrice = _.round(data.amount, 2);
    let gst_percent = data["gst_percent"];
    const charges = getExtraChargesCosting(ticketPrice, gst_percent);

    const payload = new FormData();
    payload.append("ticket_type", data.ticketType);
    if(data.email_subject) {
        payload.append("custom_email_subject", data.email_subject);
    }
    if(data.email_message) {
        payload.append("custom_email_message", data.email_message);
    }

    data["extra_charges_from_buyer"] = +data["extra_charges_from_buyer"];
    payload.append("extra_charges_from_buyer", data["extra_charges_from_buyer"]);

    payload.append("currency", data["currency"]);
    payload.append("description", data["description"] ? data["description"] : '');
    payload.append("gst_percent", String(data["gst_percent"]));
    payload.append("max_ticket_limit", data["max_ticket_limit"] ? data["max_ticket_limit"] : '');
    payload.append("quantity", data["quantity"] ? data['quantity'] : '');
    payload.append("show_available_tickets", data["show_available_ticket_count"] ? 1 : 0);
    payload.append("title", data["title"]);
    payload.append("amount", String(parseFloat(data.amount)));

    payload.append("facility_details", JSON.stringify(facilityDetails));
    const gstOnTicketPrice = (data.gst_percent / 100) * ticketPrice;
    payload.append("gst_on_ticket_price", String(parseFloat(gstOnTicketPrice)));

    payload.append("system_charges", String(parseFloat(charges.systemFee - charges.wowslyGst)));

    payload.append("wowsly_gst", String(parseFloat(charges.wowslyGst)));

    payload.append("razor_pay_fee", String(parseFloat(charges.razorPayFee)));
    payload.append("is_separated", data["is_separated"]);

    let purchasePrice = Number(data.amount || "0") + Number(gstOnTicketPrice || "0");

    if (Boolean(data["extra_charges_from_buyer"])) {
        purchasePrice += (Number(charges.razorPayFee || "0") + Number(charges.systemFee || "0"));
    }
    payload.append("purchase_price", String(purchasePrice));

    let amountToOwner = Number(data.amount || "0") + Number(gstOnTicketPrice || "0");
    if (!(Boolean(data["extra_charges_from_buyer"]))) {
        amountToOwner -= (Number(charges.razorPayFee || "0") + Number(charges.systemFee || "0"));
    }

    payload.append("amount_to_owner", String(amountToOwner));

    if (data["ticket_photo"] && data["ticket_photo"].length > 0) {
        if (typeof data["ticket_photo"] === "string") {
            payload.append("ticket_photo", data["ticket_photo"]);
        } else {
            payload.append("ticket_photo", Array.from(data["ticket_photo"])[0]);
        }
    }

    if (isEdit) {
        payload.append("_method", "PUT");
        payload.append("is_delete_photo", data.isDeletePhoto);
    }

    if (data.ticketType === 'QR CODE' && data.cropDimensions) {
        payload.append('crop_dimensions', JSON.stringify(data.cropDimensions));
    } else {
        payload.append('crop_dimensions', null);
    }

    payload.append('type', eventTicketType)

    return payload;
};