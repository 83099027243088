import { Box, Button, DialogActions, MenuItem, Select, Stack, TextField, InputAdornment, Typography } from "@mui/material";
import _ from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { EventGuestsContext } from "../../../../EventGuestContext";
import DialogBox from "../../../../../../../../../../components/DialogBox";
import { eventDetails } from "../../../../../../../../../../redux/slices/eventDataSlice";
import { codes as countryCodes } from '../../../../../../../../../../utils/constants/countries.codes';
import { manageEventGuestPayload } from "../../../../../../../../../../utils/functions/managePayload";
import { createEventGuest, getEventGuests } from "../../../../../../../../../../services/wEvents/eventGuests";
import UploadImage from "../../../../../../../../../../components/UploadImage";

const AddGuestContent = (handleClose) => {
    const { tabValue, totalGuests, setSearchedGuest, setGuests } = useContext(EventGuestsContext);
    const [guestImage, setGuestImage] = useState(null);
    const [isDeletePhoto, setIsDeletePhoto] = useState(false);

    const {
        handleSubmit,
        register,
        formState: { errors },
        reset, watch, setValue,
    } = useForm();

    const eventData = useSelector(eventDetails);

    const mySubmit = (data) => {
        let payload = manageEventGuestPayload(data);
        setSearchedGuest(null);
        createEventGuest(payload, eventData?.id).then((res) => {
            getEventGuests({ eventId: eventData?.id, page: 1, type: tabValue === 0 ? 'invited' : 'registered' }).then((res2) => {
                totalGuests.current = res2.data.meta.total;
                setGuests(res2.data.data);                
                toast.success("Guest added successfully!!", {
                    position: "top-right",
                    theme: "dark"
                });
                handleClose();
            }).catch((err) => {
                console.log(err);
            });
        }).catch((err) => {
            console.log(err);
            toast.error(Object.values(err.response?.data)[0][0], {
                position: "top-right",
                theme: "dark"
            });
        });
    };

    useEffect(() => {
        return () => {
            reset();
        };
    }, [handleClose]);

    return (
        <>
            <form
                autoComplete="off"
                onSubmit={handleSubmit(mySubmit)}
                style={{ marginTop: '1rem' }}
            >
                <Stack spacing={3}>
                    <TextField
                        fullWidth
                        color="warning"
                        label="Enter Name"
                        name="guest_name"
                        inputProps={{
                            type: 'text',
                            maxLength: 30
                        }}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">
                                <Typography>
                                    {watch('guest_name') ? watch('guest_name').length : 0}/30
                                </Typography>
                            </InputAdornment>
                        }}
                        error={_.get(errors, 'guest_name') ? true : false}
                        helperText={
                            _.get(errors, 'guest_name') ? 'This is required field' : null
                        }
                        {...register('guest_name', { required: true })}
                    />

                    <TextField
                        fullWidth
                        color="warning"
                        label="Email ID"
                        name="email"
                        inputProps={{
                            type: 'email',
                            maxLength: 30
                        }}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">
                                <Typography>
                                    {watch('email') ? watch('email').length : 0}/30
                                </Typography>
                            </InputAdornment>
                        }}
                        error={_.get(errors, 'email') ? true : false}
                        helperText={
                            _.get(errors, 'email') ? 'This is required field' : null
                        }
                        {...register('email', { required: false })}
                    />

                    <Stack direction='row' spacing={1}>
                        <Select
                            color="warning"
                            defaultValue='91'
                            error={_.get(errors, 'dialing_code') ? true : false}
                            {...register('dialing_code', { required: true })}
                            MenuProps={{
                                PaperProps: {
                                    sx: {
                                        maxHeight: "300px",
                                        width: "7rem",
                                        marginTop: "0.5rem",
                                        border: "1px solid #E4E4E4",
                                        p: 1.5,
                                        pt: 0.75,
                                        boxShadow: "0",
                                        borderRadius: "4px",
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: 2,
                                        scrollbarWidth: "0px",
                                        "&& .Mui-selected": {
                                            backgroundColor: "lightblue",
                                            border: "1px solid #E4E4E4",
                                            "&:hover": {
                                                backgroundColor: "#FAFBFD"
                                            }
                                        }
                                    }
                                }
                            }}
                        >
                            {countryCodes.map((code, index) => (
                                <MenuItem key={index} value={code.dial_code}>
                                    <Box>+{code.dial_code}</Box>
                                </MenuItem>
                            ))}
                        </Select>
                        <TextField
                            color="warning"
                            label='Phone Number'
                            inputProps={{
                                type: 'tel',
                                pattern: '[0-9]*'
                            }}
                            error={_.get(errors, 'mobile') ? true : false}
                            helperText={
                                _.get(errors, 'mobile') ? 'This is required field' : null
                            }
                            {...register('mobile', { required: true })}
                            sx={{ flex: 1 }}
                        />
                    </Stack>

                    <Box
                        sx={{
                            backgroundColor: guestImage && !isDeletePhoto ? "#171717" : "white",
                            borderRadius: "10px",
                            width: { xs: "60%", sm: "50%", lg: "40%" },
                            marginInline: "auto !important",
                            height: "9rem",
                            position: "relative",
                            border: "1px solid lightgrey"
                        }}
                    >
                        <UploadImage
                            fieldName="guest_photo"
                            label="Guest Photo"
                            register={register}
                            errors={errors}
                            selectedImage={isDeletePhoto ? null : guestImage}
                            setSelectedImage={(v) => {
                                setGuestImage(v);
                                if (isDeletePhoto) {
                                    setIsDeletePhoto(false);
                                }
                            }}
                            hideVisibility={false}
                            hideOptions={false}
                            isDeletable={true}
                            onDelete={() => {
                                setIsDeletePhoto(true);
                                setGuestImage(null);
                            }}
                        />
                    </Box>
                </Stack>

                <DialogActions sx={{ my: 1, pr: 0, pt: 1 }}>
                    <Button variant="outlined" color='warning' onClick={() => reset()}>
                        Reset
                    </Button>
                    <Button type="submit" color='warning' variant="contained" sx={{
                        boxShadow: 'none'
                    }}>
                        Add
                    </Button>
                </DialogActions>
            </form>
        </>
    )
}

const AddGuestDialog = ({ handleClose }) => {
    return (
        <>
            <DialogBox open={true} handleClose={handleClose}
                title='Add Guest' content={AddGuestContent(handleClose)}
            />
        </>
    )
};

export default AddGuestDialog;
