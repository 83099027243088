import { EVENT_API } from "../../utils/constants/apis.urls";
import { axiosInstance } from "../../config/interceptor";

export const updateEventViewTitle = (payload, viewId) => {
    payload['_method'] = 'PUT';
    return axiosInstance.post(`${EVENT_API.EVENT_MODEL_VIEW}/${viewId}`, payload)
};

export const createEventModelView = (payload) => {
    return axiosInstance.post(EVENT_API.EVENT_MODEL_VIEW, payload)
};

export const reorderEventModelViews = (payload) => {
    return axiosInstance.post(EVENT_API.REORDER_EVENT_MODEL_VIEWS, payload)
};

export const downloadEventQRForRegistrationSelfCheckIn = ({ eventId }) => {
    return axiosInstance.post(
        EVENT_API.DOWNLOAD_EVENT_QR_FOR_REGISTRATION_SELF_CHECKIN.replace(":event_id", eventId),
        {},
        {
            responseType: "blob"
        }
    );
};

export const createEventMedia = (payload) => {
    let formData = new FormData();
    formData.append('file[]', payload.image);
    formData.append('media_type', payload.type);

    return axiosInstance.post(`${EVENT_API.EVENT_MEDIA.replace(':event_id', payload.eventId)}`, formData,
        {
            headers: { "content-type": "multipart/form-data" },
        }
    )
};

export const updateEventMedia = (payload) => {
    let formData = new FormData();
    let mediaId = payload.imageId
    formData.append('_method', 'PUT')
    formData.append('file', payload.image);

    return axiosInstance.post(`${EVENT_API.EVENT_MEDIA.replace(':event_id', payload.eventId)}/${mediaId}`, formData,
        {
            headers: { "content-type": "multipart/form-data" },
        }
    )
};

export const deleteEventMedia = ({ eventId, mediaId }) => {
    return axiosInstance.delete(`${EVENT_API.EVENT_MEDIA.replace(':event_id', eventId)}/${mediaId}`,
        {
            headers: { "content-type": "multipart/form-data" },
        }
    )
}

export const getEventPrograms = ({ eventId, viewId }) => {
    return axiosInstance.get(`${EVENT_API.EVENT_PROGRAM.replace(':event_id', eventId)}?model_view_id=${viewId}`);
}

export const createEventProgram = (payload, eventId) => {
    return axiosInstance.post(EVENT_API.EVENT_PROGRAM.replace(':event_id', eventId), payload,
        {
            headers: { "content-type": "multipart/form-data" },
        }
    );
}

export const editEventProgram = (payload, eventId, eventProgramId) => {
    return axiosInstance.post(`${EVENT_API.EVENT_PROGRAM.replace(':event_id', eventId)}/${eventProgramId}`, payload,
        {
            headers: { "content-type": "multipart/form-data" },
        }
    );
}

export const deleteEventProgram = (eventId, eventProgramId) => {
    return axiosInstance.delete(`${EVENT_API.EVENT_PROGRAM.replace(':event_id', eventId)}/${eventProgramId}`);
}

export const getEventMembers = ({ eventId, viewId }) => {
    return axiosInstance.get(`${EVENT_API.EVENT_MEMBER.replace(':event_id', eventId)}?model_view_id=${viewId}`);
}

export const createEventMember = (payload, eventId) => {
    return axiosInstance.post(EVENT_API.EVENT_MEMBER.replace(':event_id', eventId), payload,
        {
            headers: { "content-type": "multipart/form-data" },
        }
    );
}

export const editEventMember = (payload, eventId, eventProgramId) => {
    return axiosInstance.post(`${EVENT_API.EVENT_MEMBER.replace(':event_id', eventId)}/${eventProgramId}`, payload,
        {
            headers: { "content-type": "multipart/form-data" },
        }
    );
}

export const deleteEventMember = (eventId, eventMemberId) => {
    return axiosInstance.delete(`${EVENT_API.EVENT_MEMBER.replace(':event_id', eventId)}/${eventMemberId}`);
}

export const getEventAlbums = ({ eventId, viewId }) => {
    return axiosInstance.get(`${EVENT_API.EVENT_ALBUM.replace(':event_id', eventId)}?model_view_id=${viewId}`);
}

export const createEventAlbum = (payload, eventId) => {
    return axiosInstance.post(EVENT_API.EVENT_ALBUM.replace(':event_id', eventId), payload);
}

export const editEventAlbum = (payload, eventId, eventAlbumId) => {
    return axiosInstance.post(`${EVENT_API.EVENT_ALBUM.replace(':event_id', eventId)}/${eventAlbumId}`,
        payload);
}

export const deleteEventAlbum = (eventId, eventAlbumId) => {
    return axiosInstance.delete(`${EVENT_API.EVENT_ALBUM.replace(':event_id', eventId)}/${eventAlbumId}`);
}

export const getAlbumImages = (albumId) => {
    return axiosInstance.get(EVENT_API.EVENT_ALBUM_MEDIA.replace(':album_id', albumId));
}

export const createAlbumMedia = (payload, albumId) => {
    return axiosInstance.post(EVENT_API.EVENT_ALBUM_MEDIA.replace(':album_id', albumId), payload, {
        headers: { "content-type": "multipart/form-data" },
    });
}

export const deleteAlbumMedia = (albumId, albumMediaId) => {
    return axiosInstance.delete(`${EVENT_API.EVENT_ALBUM_MEDIA.replace(':album_id', albumId)}/${albumMediaId}`)
}

export const getEventTickets = ({ eventId, includeHiddenTickets }) => {
    const params = {
        include_hidden_tickets: includeHiddenTickets
    };
    return axiosInstance.get(EVENT_API.EVENT_TICKET.replace(':event_id', eventId), {params});
}

export const createEventTicket = (payload, eventId) => {
    return axiosInstance.post(EVENT_API.EVENT_TICKET.replace(':event_id', eventId), payload, {
        headers: { "content-type": "multipart/form-data" },
    });
}

export const editEventTicket = (payload, eventId, ticketId) => {
    return axiosInstance.post(`${EVENT_API.EVENT_TICKET.replace(':event_id', eventId)}/${ticketId}`, payload, {
        headers: { "content-type": "multipart/form-data" },
    });
}

// export const deleteEventTicket = (eventId, ticketId) => {
//     return axiosInstance.delete(`${EVENT_API.EVENT_TICKET.replace(':event_id', eventId)}/${ticketId}`);
// }

export const hideEventTicket = (eventId, ticketId) => {
    return axiosInstance.post(EVENT_API.EVENT_TICKET_HIDE.replace(':event_id', eventId).replace(':ticket_id', ticketId));
}

export const getSoldEventTicketDetails = (eventId, ticketId, eventTicketType) => {
    return axiosInstance.get(EVENT_API.EVENT_TICKET_SOLD.replace(':event_id', eventId).replace(':ticket_id', ticketId), {
        params: {
            event_type: eventTicketType,
        }
    });
}

export const getEventForms = ({ eventId, guest_uuid, commonEvent }) => {
    if (commonEvent) { // Will be called on registration tab without login
        return axiosInstance.get(`${EVENT_API.EVENT_FORM_WITHOUT_LOGIN.replace(':event_id', eventId)}?common_event_link=true`);
    } else if (guest_uuid) { // This will get guest details from uuid when not logged in for QR code visibility
        return axiosInstance.get(`${EVENT_API.EVENT_FORM_WITHOUT_LOGIN.replace(':event_id', eventId)}?uuid=${guest_uuid}`);
    }
    // This will get get details from active user when logged in
    return axiosInstance.get(EVENT_API.EVENT_FORM.replace(':event_id', eventId));
}

export const getEventForm = ({ eventId, formId }) => {
    return axiosInstance.get(`${EVENT_API.EVENT_FORM.replace(':event_id', eventId)}/${formId}`);
}

export const createEventForm = (payload, eventId) => {
    return axiosInstance.post(EVENT_API.EVENT_FORM.replace(':event_id', eventId), payload);
}

export const deleteEventForm = (eventId, formId) => {
    return axiosInstance.delete(`${EVENT_API.EVENT_FORM.replace(':event_id', eventId)}/${formId}`);
}

export const bulkDeleteEventFormFields = (eventId, payload) => {
    return axiosInstance.post(`${EVENT_API.EVENT_FORM_FIELDS.replace(':event_id', eventId)}/bulkdelete`, payload);
}

export const insertOrUpdateEventFormFields = (eventId, formId, payload) => {
    return axiosInstance.post(`${EVENT_API.EVENT_FORM_FIELDS.replace(':event_id', eventId)}/${formId}/insertorupdate`, payload);
}

export const getEventRsvpQuestions = ({ eventId }) => {
    return axiosInstance.get(`${EVENT_API.EVENT_RSVP.replace(':event_id', eventId)}`);
}

export const insertOrUpdateEventRsvpQues = (eventId, payload) => {
    return axiosInstance.post(`${EVENT_API.EVENT_RSVP.replace(':event_id', eventId)}/insertorupdate`, payload);
}

export const bulkDeleteEventRsvpQues = (eventId, payload) => {
    return axiosInstance.post(`${EVENT_API.EVENT_RSVP.replace(':event_id', eventId)}/bulkdelete`, payload);
}

export const submitQuickEventRsvp = (eventId, payload) => {
    return axiosInstance.post(`${EVENT_API.EVENT_QUICK_RSVP.replace(':event_id', eventId)}`, payload);
}

export const getEventRsvpAnswers = ({ eventId, totalCount, countOnly, currentUserAnswer }) => {
    if (totalCount) {
        if (countOnly) { // This is basically for get only count of total event rsvps
            return axiosInstance.get(`${EVENT_API.EVENT_WEBLINK_RSVP_ANSWERS.replace(':event_id', eventId)}`);
        }
        return axiosInstance.get(`${EVENT_API.EVENT_RSVP_ANSWERS.replace(':event_id', eventId)}?total-count=${totalCount}`);
    } else if (currentUserAnswer) {
        return axiosInstance.get(`${EVENT_API.EVENT_RSVP_ANSWERS.replace(':event_id', eventId)}?current-user-answer=${currentUserAnswer}`);
    } else {
        return axiosInstance.get(`${EVENT_API.EVENT_RSVP_ANSWERS.replace(':event_id', eventId)}`);
    }
}

export const submitEventRsvpAnswers = (eventId, payload) => {
    return axiosInstance.post(`${EVENT_API.EVENT_RSVP_ANSWERS.replace(':event_id', eventId)}`, payload, {
        headers: { "content-type": "multipart/form-data" },
    });
}

export const downloadRsvp = ({ eventId }) => {
    return axiosInstance.get(`${EVENT_API.EVENT_RSVP_DOWNLOAD.replace(':event_id', eventId)}`, {
        headers: { "Access-Control-Allow-Origin": "*" }
    });
}

export const submitEventFormAnswers = (eventId, payload, { commonEvent }) => {
    if (commonEvent) {
        return axiosInstance.post(`${EVENT_API.PUBLIC_EVENT_FORM_ANSWERS.replace(':event_id', eventId)}`, payload, {
            headers: { "content-type": "multipart/form-data" },
        });
    }
    return axiosInstance.post(`${EVENT_API.EVENT_FORM_ANSWERS.replace(':event_id', eventId)}`, payload, {
        headers: { "content-type": "multipart/form-data" },
    });
}

export const submitSelectedTicket = (payload, eventId, { commonEvent }) => {
    if (commonEvent) {
        return axiosInstance.post(
            `${EVENT_API.SELECT_PUBLIC_EVENT_TICKET.replace(
                ":event_id",
                eventId
            )}`,
            payload
        );
    }
    return axiosInstance.post(
        `${EVENT_API.SELECT_EVENT_TICKET.replace(
            ":event_id",
            eventId
        )}`,
        payload
    );
};

export const substituteGuestTickets = (eventId, payload) => {
    return axiosInstance.post(
        `${EVENT_API.SUBSTITUTE_GUEST_TICKETS.replace(
            ":event_id",
            eventId
        )}`,
        payload
    );
}

export const getEventFormReplies = (eventId, payload) => {
    return axiosInstance.get(`${EVENT_API.EVENT_FORM_ANSWERS.replace(':event_id', eventId)}`, {
        current_timezone: payload
    });
}

export const downloadEventFormReplies = (eventId, payload) => {
    return axiosInstance.get(`${EVENT_API.EVENT_FORM_ANSWERS_DOWNLOAD.replace(':event_id', eventId)}`, {
        params: {
            current_timezone: payload,
        }
    });
}

export const getEventPaidPayments = ({ eventId, page }) => {
    return axiosInstance.get(
        `${EVENT_API.EVENT_PAID_PAYMENTS.replace(
            ":event_id",
            eventId
        )}?page=${page}`
    );
};

export const getEventPaidPaymentDetails = (eventId, guestTicketId) => {
    return axiosInstance.get(
        `${EVENT_API.EVENT_PAID_PAYMENT_DETAILS.replace(
            ":event_id",
            eventId
        ).replace(":guest_ticket_id", guestTicketId)
        }`
    );
};

export const getEventReceivedPayments = ({ eventId, page }) => {
    return axiosInstance.get(
        `${EVENT_API.EVENT_RECEIVED_PAYMENTS.replace(
            ":event_id",
            eventId
        )}?page=${page}`
    );
};

export const getEventReceivedPaymentDetails = (eventId, guestTicketId) => {
    return axiosInstance.get(
        `${EVENT_API.EVENT_RECEIVED_PAYMENT_DETAILS.replace(
            ":event_id",
            eventId
        ).replace(":guest_ticket_id", guestTicketId)
        }`
    );
};