import React from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Box, FormControlLabel, Grid, IconButton, Radio, Skeleton, Stack, Typography, TextField } from "@mui/material";
import { ticketCurrencies } from "../../../../../../../../utils/constants/ticketCurrency";
import { parseDecimalNumber } from "../../../../../../../../utils/functions/getFormattedNumber";
import { eventDetails } from "../../../../../../../../redux/slices/eventDataSlice";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
const TicketSelectionCard = ({ t, handleTicketViewClick, ticketsCount, max_value, handlePlusTicketCount, handleMinusTicketCount }) => {
    const ticketCurrencySymbol = ticketCurrencies.find((tc) => tc.value === t?.currency)?.symbol;
    const eventDetailsObj = useSelector(eventDetails);
    return (
        <Grid item xs={12} lg={5.5}>
            {
                eventDetailsObj.is_multiple_tickets === 1 ? (
                    t ? (
                        <Box
                            sx={{
                                py: 2,
                                pl: 2,
                                border: "2px solid lightgrey",
                                borderRadius: "5px",
                                ":hover": {
                                    backgroundColor: "rgba(255, 131, 89, 0.16)",
                                },
                            }}
                        >
                            <Stack
                                spacing={3}
                                direction="row"
                                justifyContent="space-between"
                                sx={{ width: "100%" }}
                            >
                                <Stack spacing={1} justifyContent="center" sx={{ ml: 1 }}>
                                    <Typography sx={{ fontWeight: "500", fontSize: "1.1rem" }}>
                                        {t.title}
                                    </Typography>
                                    {eventDetailsObj.is_paid && (
                                        <Typography sx={{ color: "#6F738B" }}>
                                            Price: {parseDecimalNumber(t.purchase_price, 2)}{" "}
                                            <span dangerouslySetInnerHTML={{ __html: ticketCurrencySymbol }} />
                                        </Typography>
                                    )}
                                </Stack>
                                <IconButton
                                    onClick={() => handleTicketViewClick(t)}
                                    sx={{ ":hover": { backgroundColor: "transparent" } }}
                                >
                                    <VisibilityIcon sx={{ color: "#1F274A", fontSize: "1.3rem" }} />
                                </IconButton>
                            </Stack>

                            {/* Ticket Count Selector */}
                            <Stack direction="row" spacing={2} alignItems="center" sx={{ mt: 2 }}>
                                <Typography sx={{ color: "rgba(0, 0, 0, 0.6)" }}>
                                    Number of tickets:
                                </Typography>
                                <Stack direction="row" spacing={1} alignItems="center">
                                    <IconButton onClick={() => handleMinusTicketCount(t.id)}>
                                        <RemoveCircleOutlineIcon color="warning" />
                                    </IconButton>
                                    <TextField
                                        color="warning"
                                        sx={{ width: "5rem" }}
                                        inputProps={{
                                            type: "number",
                                            min: 0,
                                            max: max_value,
                                            readOnly: true, // Disable manual input, so only the buttons work
                                        }}
                                        value={ticketsCount || 0}
                                    />
                                    <IconButton onClick={() => handlePlusTicketCount(t.id, max_value)}>
                                        <AddCircleOutlineIcon color="warning" />
                                    </IconButton>
                                </Stack>
                            </Stack>
                        </Box>
                    ) : (
                        <Box sx={{ py: 0.5, borderRadius: "5px" }}>
                            <Skeleton sx={{ transform: "scale(1,1)", height: "5rem" }} />
                        </Box>
                    )
                ) : (
                    t ? (
                        <Box
                            onClick={() => {
                                if (t.quantity - t.sold_out <= 0) {
                                    toast.error("All tickets are sold out", {
                                        position: "top-right",
                                        theme: "dark",
                                    });
                                }
                            }}
                            sx={{
                                py: 2,
                                pl: 2,
                                border: "2px solid lightgrey",
                                borderRadius: "5px",
                                ":hover": {
                                    backgroundColor: "rgba(255, 131, 89, 0.16)",
                                },
                            }}
                        >
                            <FormControlLabel
                                disableTypography
                                sx={{ width: "100%" }}
                                value={t.id}
                                disabled={t.quantity - t.sold_out > 0 ? false : true}
                                label={
                                    <Stack
                                        spacing={3}
                                        direction="row"
                                        justifyContent="space-between"
                                        sx={{ width: "100%" }}
                                    >
                                        <Stack spacing={1} justifyContent="center" sx={{ ml: 1 }}>
                                            <Typography sx={{ fontWeight: "500", fontSize: "1.1rem" }}>
                                                {t.title}
                                            </Typography>
                                            {eventDetailsObj.is_paid ? (
                                                <Typography sx={{ color: "#6F738B" }}>
                                                    Price: {parseDecimalNumber(t.purchase_price, 2)}{" "}
                                                    <span dangerouslySetInnerHTML={{ __html: ticketCurrencySymbol }} />
                                                </Typography>
                                            ) : (
                                                <></>
                                            )}
                                        </Stack>
                                        <IconButton
                                            onClick={() => handleTicketViewClick(t)}
                                            sx={{
                                                ":hover": {
                                                    backgroundColor: "transparent",
                                                },
                                            }}
                                        >
                                            <VisibilityIcon sx={{ color: "#1F274A", fontSize: "1.3rem" }} />
                                        </IconButton>
                                    </Stack>
                                }
                                control={<Radio color="warning" />}
                            />
                        </Box>
                    ) : (
                        <Box
                            sx={{
                                py: 0.5,
                                borderRadius: "5px",
                            }}
                        >
                            <Skeleton
                                sx={{
                                    transform: "scale(1,1)",
                                    height: "5rem",
                                }}
                            />
                        </Box>
                    )
                )
            }

        </Grid>
    );
};

export default TicketSelectionCard;
