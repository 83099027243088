import { useSelector } from "react-redux";
import React, { useRef, useState, useEffect } from "react";
import { Box, Divider, Stack, useMediaQuery } from "@mui/material";
import GuestList from "./components/GuestList";
import GuestFooter from "./components/GuestFooter";
import GuestHeader from "./components/GuestHeader";
import { EventGuestsContext } from "./EventGuestContext";
import { eventDetails } from "../../../../../../redux/slices/eventDataSlice";
import { getEventGuests } from "../../../../../../services/wEvents/eventGuests";
import { ALL_PERMISSION_ROLES } from "../../../../../../utils/constants/roles";
import EventGuestTabs from "./EventGuestTabs";

const EventGuests = () => {
    const eventData = useSelector(eventDetails)
    let totalGuests = useRef(null);
    let per_page_guests = useRef(5);

    const [guests, setGuests] = useState(null)
    const [searchedGuest, setSearchedGuest] = useState(null);
    const [checkedGuests, setChecked] = useState([]);
    const [isCheckboxVisible, setVisible] = useState(false);
    const [inviteMsg, setInviteMsg] = useState(null);
    const [paymentDone, setPayment] = useState(false); //Set to false when integrating payment module
    const [paymentId, setPaymentId] = useState(null); //Set ID when payment done
    const [scheduledDate, setScheduledDate] = useState(null);
    const [guestInviteActiveStep, setGuestInviteActiveStep] = useState(0);
    const isAllPermission = ALL_PERMISSION_ROLES.includes(eventData?.current_user_role);

    const [tabValue, setTabValue] = useState((isAllPermission || eventData?.current_user_role === 'manager') ? 0 : null);
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleToggle = (value) => () => {
        const currentIndex = checkedGuests.indexOf(value);
        const newChecked = [...checkedGuests];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setChecked(newChecked);
    };

    const isSmallDevice = useMediaQuery("(max-width:600px)"); // Smaller than tablets

    useEffect(() => {
        setGuests(null);
        if (!tabValue) {
            setChecked([]);
        }
        getEventGuests({ eventId: eventData?.id, page: 1, type: tabValue === 0 ? 'invited' : 'registered', searchedGuest: searchedGuest }).then((res) => {
            totalGuests.current = res.data.meta.total
            per_page_guests.current = res.data.meta.per_page
            setGuests(res.data.data)
        }).catch((err) => {
            console.log(err);
        })
    }, [tabValue]);

    return (
        <>
            <EventGuestsContext.Provider value={{
                tabValue, guests, setGuests, totalGuests, per_page_guests,
                searchedGuest, setSearchedGuest, inviteMsg, setInviteMsg, isCheckboxVisible, setVisible, 
                checkedGuests, setChecked, guestInviteActiveStep, setGuestInviteActiveStep,
                paymentDone, setPayment, paymentId, setPaymentId, scheduledDate, setScheduledDate, handleToggle
            }}>
                <Stack sx={{ position: 'relative' }}>
                    <GuestHeader tabValue={tabValue} handleTabChange={handleTabChange} />
                    <Divider sx={{ my: 1 }} />
                    {
                        isSmallDevice ?
                            <EventGuestTabs tabValue={tabValue} handleTabChange={handleTabChange} />
                            : <GuestList />
                    }
                    {/* As we are showing options of cancel and invite in footer only in small devices */}
                    {
                        (totalGuests.current > 0 && isAllPermission) ?
                            (isSmallDevice ? <GuestFooter /> : null)
                            : null
                    }
                    <Box id='container'></Box>
                </Stack>
            </EventGuestsContext.Provider>
        </>
    );
};

export default EventGuests;
