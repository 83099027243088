import { Box, Grid, Skeleton, Stack, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import eventsGif from '../../../../assets/events-pic.gif';
import GradientBtn from "../../../../components/GradientBtn";
import { userDetails } from "../../../../redux/slices/userDataSlice";
import { getEvents } from "../../../../services/wEvents/event";
import { PAGE_PATHS } from "../../../../utils/constants/page-path.constants";
import EventCard from "./components/EventCard";
import EventsPagination from "./components/EventsPagination";
import SearchEvent from "./components/SearchEvent";
import noSearchResultsGif from '../../../../assets/no-search-results.gif';

const AllEvents = () => {
    const userData = useSelector(userDetails);
    const [events, setEvents] = useState(null);
    const [searchedEvent, setSearchedEvent] = useState(null);
    const eventsCount = useRef(0);
    const perPageEvents = useRef(0);
    const isSmallDevice = useMediaQuery("(max-width:600px)");

    useEffect(() => {
        getEvents({ page: 1 }).then((res) => {
            eventsCount.current = res.data.meta.total
            perPageEvents.current = res.data.meta.per_page
            setEvents(res?.data.data);
        }).catch((err) => {
            console.log('Error: ', err);
            toast.error("Something went wrong in fetching events", {
                position: "top-right",
                theme: "dark"
            });
        })
    }, []);

    return <>
        {(events?.length !== 0 || searchedEvent) ?
            <Grid container sx={{ px: { xs: 3, sm: 3, md: 2, lg: 1, xl: 3 }, mb: { xs: 2.5, sm: 2, lg: 3 }, mt: 2 }}>
                <Grid item xs={12} sm={5} md={4}>
                    <SearchEvent eventsCount={eventsCount} perPageEvents={perPageEvents}
                        setEvents={setEvents} searchedEvent={searchedEvent} setSearchedEvent={setSearchedEvent} />
                </Grid>
                <Grid item xs={0} sm={3} md={4}></Grid>
                <Grid item xs={0} sm={4} md={4} sx={{ textAlign: 'right' }}>
                    <GradientBtn label='Create Event' redirectPath={`${PAGE_PATHS.CREATE_EVENT}`} />
                </Grid>
            </Grid> :
            null
        }

        {
            events?.length === 0 ?
                (searchedEvent ? // That means something has been searched and not found any matching results for that 
                <Stack justifyContent='center' alignItems='center' spacing={2}>
                    <Box component='img'
                        src={noSearchResultsGif}
                        width={isSmallDevice ? '90%' : '25rem'}
                        height='22rem'
                    />
                    <Typography sx={{ fontWeight: 'bold', fontSize: '1.1rem' }}>No Events Found!!</Typography>
                </Stack> :
                    <Stack justifyContent='center' alignItems='center' spacing={2}>
                        <Box component='img'
                            src={eventsGif}
                            width={isSmallDevice ? '90%' : '27rem'}
                            height={isSmallDevice ? '15rem' : '20rem'}
                        />
                        <Typography sx={{ fontWeight: 'bold', fontSize: '1.4rem' }}>Welcome {userData?.full_name}!</Typography>
                        <Typography sx={{
                            width: { xs: "95%", lg: '90%' },
                            px: { md: 3 },
                            pb: { xs: 8, sm: 0 },
                            textAlign: 'center'
                        }}>
                            We're thrilled to help you organize your event effortlessly.
                            With user-friendly platform *Wowsly*, you can easily create and customize your event, send invitations via SMS, WhatsApp, and email, and effortlessly collect RSVPs from your guests.
                            Let's get started and make your event a success!
                        </Typography>
                        <Box sx={{ mt: 2 }}>
                            <GradientBtn label='Create Event' redirectPath={`${PAGE_PATHS.CREATE_EVENT}`} />
                        </Box>
                    </Stack>
                ) :
                <>
                    <Grid container rowSpacing={{ xs: 4, md: 5, lg: 6 }} columnSpacing={2} pb={3}>
                        {(events ?? Array.from(new Array(3))).map((e, i) => (
                            <EventCard key={i} eventObj={e} setEvents={setEvents} />
                        ))}
                    </Grid>
                    {(events) ?
                        <>
                            {eventsCount.current > perPageEvents.current ?
                                <EventsPagination
                                    eventsCount={eventsCount.current}
                                    perPageEvents={perPageEvents.current}
                                    setEvents={setEvents} />
                                : null
                            }
                        </>
                        :
                        <Stack sx={{ alignItems: 'center' }}>
                            <Skeleton width='50%' animation="wave"
                                sx={{ mx: '1rem !important', p: '0.5rem !important' }}
                            />
                        </Stack>
                    }
                </>
        }
    </>;
};

export default AllEvents;
