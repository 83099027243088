import { Button, DialogActions, Stack } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import DialogBox from "../../../../../../../../../components/DialogBox";
import RenderFormFields from "../../../../../../../../../components/RenderFormFields";
import { eventDetails } from "../../../../../../../../../redux/slices/eventDataSlice";
import { editEventMember } from "../../../../../../../../../services/wEvents/eventDetails";
import { editEventMemberForm } from "../../../../../../../../../utils/constants/editEventMember";
import { manageEventMemberPayload } from "../../../../../../../../../utils/functions/managePayload";
import { EventKeyMembersContext } from "..";
import pluralize from "pluralize";

const EditMemberContent = (handleClose, setItems, memberDetails) => {
    const {
        handleSubmit,
        register,
        formState: { errors },
        control, reset
    } = useForm();

    const eventData = useSelector(eventDetails);
    const { title } = useContext(EventKeyMembersContext);
    const initialRender = useRef(true);
    const [imageToCrop, setImageToCrop] = useState(memberDetails?.photo);
    const onImageChoose = (img) => {
        setImageToCrop(img);
    }

    const mySubmit = (data) => {
        // console.log(data);
        let payload = manageEventMemberPayload(data, true)
        // console.log(Object.fromEntries(payload))
        editEventMember(payload, eventData?.id, memberDetails?.id).then((res) => {
            console.log('---------------->', res.data.data);
            setItems((items) => {
                let ele = items.find(i => i.id === res.data?.data?.id);
                items[items.indexOf(ele)] = res.data.data;
                return [...items];
            })
            toast.success(`${pluralize.singular(title)} Updated Successfully!!`, {
                position: "top-right",
                theme: "dark"
            });
            handleClose();
        }).catch((err) => {
            console.log(err);
            toast.error(Object.values(err.response.data)[0][0], {
                position: "top-right",
                theme: "dark"
            });
        })
    };

    useEffect(() => {
        return () => {
            reset();
            if (initialRender.current) {
                initialRender.current = false;
            } else {
                setImageToCrop(memberDetails?.photo);
            }
        };
    }, [handleClose]);

    return (
        <>
            <form
                autoComplete="off"
                onSubmit={handleSubmit(mySubmit)}
                style={{ marginTop: '1rem' }}
            >
                <Stack spacing={3}>
                    {editEventMemberForm.data.map((item, index) => (
                        <RenderFormFields
                            key={index}
                            item={item}
                            eventData={eventData}
                            register={register}
                            errors={errors}
                            control={control}
                            editForm={true}
                            cardDetails={memberDetails}
                            file={imageToCrop}
                            setFile={setImageToCrop}
                            onImageChoose={onImageChoose}
                        />
                    ))}
                </Stack>

                <DialogActions sx={{ my: 1, pr: 0, pt: 1 }}>
                    <Button variant="outlined" color='warning' onClick={() => reset()}>
                        Reset
                    </Button>
                    <Button type="submit" color='warning' variant="contained" sx={{
                        boxShadow: 'none'
                    }}>
                        Save Changes
                    </Button>
                </DialogActions>
            </form>
        </>
    )
}

const EditMemberDialog = ({ handleClose, setItems, memberDetails }) => {
    return (
        <DialogBox open={true} handleClose={handleClose}
            title='Edit Person' content={EditMemberContent(handleClose, setItems, memberDetails)}
        />
    )
};

export default EditMemberDialog;