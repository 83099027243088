    import SaveIcon from '@mui/icons-material/Save';
    import { LoadingButton } from "@mui/lab";
    import { Box, Button, Checkbox, DialogActions, FormControl, FormControlLabel, FormGroup, FormHelperText, MenuItem, Radio, RadioGroup, Select, Skeleton, Stack, Switch, TextField, Typography } from "@mui/material";
    import React, { useContext, useEffect, useState } from "react";
    import { Controller, useForm } from "react-hook-form";
    import { useSelector } from "react-redux";
    import { useParams } from 'react-router-dom';
    import { toast } from "react-toastify";
    import { GuestRegistrationContext } from "../..";
    import { eventDetails } from "../../../../../../../../redux/slices/eventDataSlice";
    import { getEventTickets, submitEventFormAnswers } from "../../../../../../../../services/wEvents/eventDetails";
    import { manageModelFormAnswerPayload } from "../../../../../../../../utils/functions/managePayload";
    import { userDetails } from '../../../../../../../../redux/slices/userDataSlice';
    import { codes as countryCodes } from '../../../../../../../../utils/constants/countries.codes';
    import { EventDetailsContext } from '../../../../../..';
    import UploadImage from '../../../../../../../../../src/components/UploadImage';

    const GuestRegistrationSection = () => {
        const { guestId, commonEventTitle } = useParams();
        const [loader, setLoader] = useState(false);
        const [selectedCountryCode, setCountryCode] = useState(91);
        const { handleSlideChange, rForm, tickets, setTickets,
            setSelectedTicket, registrationData,
            setRegistrationData, setPaymentDetails } = useContext(GuestRegistrationContext);
        const { setTabValue } = useContext(EventDetailsContext);
        const eventDetailsObj = useSelector(eventDetails);
        const userData = useSelector(userDetails);
        const eventTicketType = eventDetailsObj?.is_paid ? 'paid' : 'free';
        const activeUserId = (eventTicketType === 'free' && tickets?.length === 0) ? (
            eventDetailsObj?.current_user_role === 'manager' ?
                eventDetailsObj?.guest_id : (
                    eventDetailsObj?.current_user_role === 'owner' ? eventDetailsObj?.event_owner_id : null)) :
            null;

        const [images, setImages] = useState({});
        const [deleteStatus, setDeleteStatus] = useState({});

        const { handleSubmit, register, reset, control, formState: { errors } } = useForm();

        useEffect(() => {
            // fetch only unhidden tickets
            getEventTickets({ eventId: eventDetailsObj?.id, includeHiddenTickets: 0 }).then((res) => {
                setTickets(res.data.data);
            }).catch((err) => {
                console.log(err);
            });
        }, []);

        const countryData = countryCodes.find((c) => Number(c.dial_code) === selectedCountryCode);

        const getPhoneLengthArray = (lengthArray) => {
            if (Array.isArray(lengthArray)) {
                return lengthArray;
            } else if (typeof lengthArray === 'number') {
                return [lengthArray];
            } else {
                return [];
            }
        };

        const generateLengthErrorMessage = (phoneLength) => {
            if (phoneLength.length === 1) {
                return `Mobile Number should contain ${phoneLength[0]} digits`;
            } else {
                const lastDigit = phoneLength[phoneLength.length - 1];
                const remainingDigits = [...phoneLength.slice(0, -1)];
                return `Mobile Number should contain ${remainingDigits.join(', ')} or ${lastDigit} digits`;
            }
        };
        
        const mySubmit = (data) => {
            setLoader(true);
            setRegistrationData(data);
        
            // Create a copy of the data object to update the keys
            const updatedData = { ...data };
        
            // Iterate over the fields to identify file type and update the keys in data object
            rForm.fields.forEach(item => {
                if (item.type === 'file' && data[item.question]) {
                    updatedData[item.id] = data[item.question];
                    delete updatedData[item.question];
                }
            });
            let payload = manageModelFormAnswerPayload(updatedData, {});
            payload.append("dialing_code", Object.values(updatedData)[1]);
            payload.append("mobile", Object.values(updatedData)[2]);
            payload.append("event_ticket_type", eventDetailsObj.is_paid ? 'paid' : 'free');
            payload.append("registered_by", activeUserId);
        
            // Find the index for the image question
            const imageQuestionIndex = rForm.fields.findIndex((field) => field.type === 'file');
        
            // Append the uploaded image file to the payload in the required format
            if (images && !deleteStatus && imageQuestionIndex !== -1) {
                payload.append(`QAs[${imageQuestionIndex}][answer]`, images);
            }
        
            submitEventFormAnswers(eventDetailsObj?.id, payload, { commonEvent: commonEventTitle ? true : false }).then((res) => {
                    //We are showing details filled in form so we need to set this in context
                    const guest_data = {
                        // guest_uuid: res.data?.['guestData'].guest_uuid, // Because this can be same or new guests uuid
                        user_id: (commonEventTitle || (eventDetailsObj?.current_user_role !== null && ['owner', 'manager'].includes(eventDetailsObj?.current_user_role))) ? res.data?.['guest_data'].user_id : userData?.id,
                        guest_uuid: (commonEventTitle || (eventDetailsObj?.current_user_role !== null && ['owner', 'manager'].includes(eventDetailsObj?.current_user_role))) ? res.data?.['guest_data'].uuid : guestId, // As currently not kept dynamic
                        guest_name: res.data?.['QAs'].find((e) => e.question === 'Name')?.answer,
                        guest_dial_code: res.data?.['QAs'].find((e) => e.question === 'Country Code')?.answer,
                        guest_number: res.data?.['QAs'].find((e) => e.question === 'Mobile Number')?.answer,
                        guest_email: res.data?.['QAs'].find((e) => e.question === 'Email')?.answer,
                    }
                    setPaymentDetails((paymentDetails) => ({
                        ...paymentDetails,
                        ...guest_data
                    }));
                if (!eventDetailsObj.is_private) { // Need this only for public event for private we can get details because user already would have logged in.
                    setRegistrationData((prevData) => ({
                        ...prevData, uuid: res.data?.['guest_data']?.uuid
                    }));
                }
                setLoader(false);
                // for owner and manager navigate to event page instead of showing successful registration for specific case
                if (activeUserId && (eventTicketType === 'free' && tickets?.length === 0)) {
                    toast.success("You have successfully registered for the event. Check your WhatsApp and Email for event details!", {
                        position: "top-right",
                        theme: "dark"
                    });
                    setTabValue(0);  // navigate to info tab
                    return;
                }
                handleSlideChange(1);
                toast.success((eventTicketType === 'free' && tickets?.length === 0) ? "You have successfully registered for the event. Check your WhatsApp and Email for event details!" : "Registration form saved", {
                    position: "top-right",
                    theme: "dark"
                });
            }).catch((err) => {
                console.log(err);
                toast.error(err.response.data.error, {
                    position: "top-right",
                    theme: "dark"
                })
                setLoader(false);
            })
        }

        const handleResetForm = () => {
            reset();
            setSelectedTicket(null);
        }

        const renderItem = (item) => {
            if (item) {
                switch (item.type) {
                    case 'text':
                        return (
                            <>
                                <TextField
                                    fullWidth
                                    size="small"
                                    color="warning"
                                    label={item.question}
                                    defaultValue={registrationData?.[item.id]}
                                    error={errors[item.id] ? true : false}
                                    helperText={
                                        errors[item.id] ? 'This is required field' : null
                                    }
                                    {...register(`${item.id}`, { required: Boolean(item.mandatory) })}
                                />
                            </>
                        )
                    case 'number':
                        return (
                            <Stack direction='row' spacing={1}>
                                <Select
                                    color="warning"
                                    size="small"
                                    defaultValue='91'
                                    {...register(`${item.id}`, { required: Boolean(item.mandatory) })}
                                    onChange={(e) => setCountryCode(e.target.value)}
                                    MenuProps={{
                                        anchorOrigin: {
                                            vertical: "top",
                                            horizontal: "left",
                                        },
                                        transformOrigin: {
                                            vertical: "bottom",
                                            horizontal: "left",
                                        },
                                        getcontentanchorel: null,
                                        PaperProps: {
                                            sx: {
                                                maxHeight: "210px",
                                                border: "1px solid #E4E4E4",
                                                pt: 0.75,
                                                boxShadow: "0",
                                                borderRadius: "4px",
                                                display: "flex",
                                                flexDirection: "column",
                                                gap: 2,
                                                scrollbarWidth: "0px",
                                                "&& .Mui-selected": {
                                                    backgroundColor: "lightblue",
                                                    border: "1px solid #E4E4E4",
                                                    "&:hover": {
                                                        backgroundColor: "#FAFBFD"
                                                    }
                                                }
                                            }
                                        }
                                    }}
                                >
                                    {countryCodes.map((code, index) => (
                                        <MenuItem key={index} value={code.dial_code}>
                                            <Box>+{code.dial_code}</Box>
                                        </MenuItem>
                                    ))}
                                </Select>
                                <TextField
                                    fullWidth
                                    size="small"
                                    color="warning"
                                    label={"Mobile Number"}
                                    defaultValue={registrationData?.[item.id+1]}
                                    error={errors[item.id+1] ? true : false}
                                    helperText={
                                        errors[item.id+1]?.message
                                    }
                                    {...register(`${item.id + 1}`, {
                                    required: {
                                        value: Boolean(item.mandatory),
                                        message: "This is required field",
                                    },
                                    validate: (value) => {
                                        const phoneLengthArray = getPhoneLengthArray(countryData?.phoneLength);
                                        if (/\s/.test(value)) {
                                            return 'Mobile Number should not contain spaces';
                                        }
                                        if (!phoneLengthArray.includes(value.length)) {
                                            return generateLengthErrorMessage(phoneLengthArray);
                                        }
                                        if (!/^[1-9][0-9]*$/.test(value)) {
                                            return 'Invalid mobile number.';
                                        }
                                        return true;
                                    }
                                    })}
                                />
                            </Stack>
                        )
                    case 'textarea':
                        return (
                            <>
                                <TextField
                                    fullWidth
                                    multiline
                                    rows={3}
                                    size="small"
                                    color="warning"
                                    label={item.question}
                                    defaultValue={registrationData?.[item.id]}
                                    error={errors[item.id] ? true : false}
                                    helperText={
                                        errors[item.id] ? 'This is required field' : null
                                    }
                                    {...register(`${item.id}`, { required: Boolean(item.mandatory) })}
                                />
                            </>
                        );
                    case 'switch':
                        return (
                            <>
                                <Stack direction='row' alignItems='center' justifyContent='space-between' spacing={2}>
                                    <Typography sx={{ fontSize: { xs: '1rem' } }}>
                                        {item.question}
                                    </Typography>
                                    <Switch
                                        color='warning'
                                        defaultChecked={registrationData?.[item.id]}
                                        {...register(`${item.id}`)}
                                    />
                                </Stack>
                            </>
                        )
                    case 'radio':
                        return (
                            <>
                                <FormControl error={(errors[item.id]) ? true : false}>
                                    <Typography sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>{item.question}</Typography>
                                    <Controller
                                        rules={{ required: Boolean(item.mandatory) }}
                                        name={`${item.id}`}
                                        control={control}
                                        defaultValue={registrationData?.[item.id]} // This is for setting default value in useform key
                                        render={({ field }) => (
                                            <RadioGroup row
                                                defaultValue={registrationData?.[item.id] || ''} // Provide a default value if registrationData?.[item.id] is undefined
                                                onChange={e => field.onChange(e.target.value)} // Manually update the value in the field
                                            >
                                                {item.options.map((option, i) => (
                                                    <FormControlLabel key={i} value={option}
                                                        label={option} control={<Radio color="warning" />} />
                                                ))}
                                            </RadioGroup>
                                        )}
                                    />
                                    {errors[item.id] ? <FormHelperText>Please choose one option</FormHelperText> : null}
                                </FormControl>
                            </>
                        );
                        case 'file':
                    return (
                        <> 
                        <Box
                            sx={{
                                backgroundColor: item.question ? "white" : "#171717",
                                borderRadius: "10px",
                                width: { xs: "60%", sm: "50%", lg: "40%" },
                                height: "9rem",
                                position: "relative",
                                border: errors[item.question] ? '1px solid red' : '1px solid lightgrey',
                            }}
                        >
                            <UploadImage
                                fieldName={item.question}
                                label={item.question}
                                register={register} // Pass register to UploadImage
                                errors={errors} // Pass errors to UploadImage
                                selectedImage={deleteStatus[item.id] ? null : images[item.id]}
                                setSelectedImage={(v) => {
                                    setImages((prev) => ({ ...prev, [item.id]: v }));
                                    if (deleteStatus[item.id]) {
                                        setDeleteStatus((prev) => ({ ...prev, [item.id]: false }));
                                    }
                                }}
                                isDeletable={true}
                                onDelete={() => {
                                    setDeleteStatus((prev) => ({ ...prev, [item.id]: true }));
                                    setImages((prev) => ({ ...prev, [item.id]: null }));
                                }}
                            />
                        </Box>
                        </>
                    );
                    case 'checkbox':
                        return (
                            <>
                                <FormControl error={(errors[item.id]) ? true : false}>
                                    <Typography sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>{item.question}</Typography>
                                    <Controller
                                        rules={{ required: Boolean(item.mandatory) }}
                                        name={`${item.id}`}
                                        control={control}
                                        render={({ field }) => (
                                            <FormGroup row>
                                                {item.options.map((option, i) => (
                                                    <FormControlLabel
                                                        key={i}
                                                        control={<Checkbox color="warning" />}
                                                        label={option}
                                                        value={option}
                                                        checked={Array.isArray(field.value) && field.value.includes(option)} // Check if field.value is an array and only then use includes
                                                        onChange={(e) => {
                                                            const newValue = Array.isArray(field.value) ? [...field.value] : []; // Create a copy of current value array or start with an empty array
                                                            if (e.target.checked) {
                                                                newValue.push(option); // Add option if checked
                                                            } else {
                                                                const index = newValue.indexOf(option);
                                                                if (index !== -1) {
                                                                    newValue.splice(index, 1); // Remove option if unchecked
                                                                }
                                                            }
                                                            field.onChange(newValue); // Update the field value with new array
                                                        }}
                                                    />
                                                ))}
                                            </FormGroup>
                                        )}
                                    />
                                    {errors[item.id] ? <FormHelperText>Please choose at least one option</FormHelperText> : null}
                                </FormControl>
                            </>
                        )
                    default:
                        return <></>;
                }
            } else {
                return (
                    <Stack spacing={1.5} sx={{ pt: 1 }}>
                        <Skeleton sx={{
                            transform: 'scale(1,1)',
                            width: '50%', height: '0.75rem'
                        }} />
                        <Skeleton sx={{ transform: 'scale(1,1)' }} />
                    </Stack>
                )
            }
        }
        return (
            <>
                <form
                    autoComplete="off"
                    onSubmit={handleSubmit(mySubmit)}
                >
                    {rForm ? <Typography fontWeight='bold' textAlign='center' sx={{ py: 1 }}>
                        {rForm.title}
                    </Typography> :
                        <Skeleton sx={{ transform: 'scale(1,1)', width: '35%', height: '1.5rem' }} />}
                    <Stack spacing={2} sx={{ py: '1rem' }}>
                        {(rForm ? rForm.fields : Array.from(new Array(3)))?.map((item, i) => (
                            <React.Fragment key={i}>
                                {/* it combines both the fields dialing code and mobile number into one component */}
                                {(item?.question !== 'Mobile Number') && renderItem(item)}
                            </React.Fragment>
                        ))}
                    </Stack>

                    {rForm ?
                        <DialogActions sx={{ pr: 0 }}>
                            <Button color='warning' variant="outlined"
                                sx={{ boxShadow: 'none' }} onClick={handleResetForm}>
                                Reset
                            </Button>
                            <LoadingButton
                                type="submit"
                                color="warning"
                                variant="contained"
                                loading={loader ? true : false}
                                startIcon={loader ? <SaveIcon /> : null}
                                loadingPosition={loader ? "start" : null}
                            >
                                {loader ? 'Saving...' : ((eventTicketType === 'free' && tickets?.length === 0) ? 'Submit' : 'Next')}
                            </LoadingButton>
                        </DialogActions>
                        : null
                    }
                </form>
            </>
        )
    };

    export default GuestRegistrationSection;
