import { Button, DialogActions, Stack } from "@mui/material";
import pluralize from "pluralize";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { EventProgramContext } from "..";
import CropImage from "../../../../../../../../../components/CropImage";
import DialogBox from "../../../../../../../../../components/DialogBox";
import RenderFormFields from "../../../../../../../../../components/RenderFormFields";
import { eventDetails } from "../../../../../../../../../redux/slices/eventDataSlice";
import { editEventProgram } from "../../../../../../../../../services/wEvents/eventDetails";
import { editProgramForm } from "../../../../../../../../../utils/constants/editEventProgram";
import { manageEventProgramPayload } from "../../../../../../../../../utils/functions/managePayload";
import { programFieldsToHide } from "../../../../../../../../../utils/constants/eventProgramHideFields";

const EditProgramContent = (handleClose, setItems, eventProgramDetails) => {
    const {
        handleSubmit,
        register,
        formState: { errors },
        control, reset, setValue, watch
    } = useForm();
    const eventData = useSelector(eventDetails)
    const { title } = useContext(EventProgramContext);

    const initialRender = useRef(true);
    const [cropDialog, setCropDialog] = useState(false);
    const handleDialogOpen = () => {
        setCropDialog(true);
    }
    const handleDialogClose = () => {
        setCropDialog(false);
        setImageToCrop(null);
    }

    const [imageToCrop, setImageToCrop] = useState(eventProgramDetails?.photo);
    const onImageChoose = (img) => {
        setImageToCrop(img);
        handleDialogOpen();
    }
    const setFormImage = (croppedImage) => {
        handleDialogClose();
        setImageToCrop(croppedImage);
        setValue('file', croppedImage);
    }

    const mySubmit = (data) => {
        if (data.end_datetime && data.start_datetime >= data.end_datetime) {
            toast.error('Start date must be less than end date', {
                position: "top-right",
                theme: "dark"
            });
            return;
        }

        const mediaId = eventProgramDetails?.media.length !== 0 ? eventProgramDetails?.media[0].id : null
        let payload = manageEventProgramPayload(data, eventData, true, mediaId);
        editEventProgram(payload, eventData?.id, eventProgramDetails?.id).then((res) => {
            setItems((items) => {
                let ele = items.find(i => i.id === res.data?.data?.id);
                items[items.indexOf(ele)] = res.data.data;
                return [...items];
            })
            toast.success(`${pluralize.singular(title)} Updated Successfully!!`, {
                position: "top-right",
                theme: "dark"
            });
            handleClose();
        }).catch((err) => {
            console.log(err)
            toast.error(Object.values(err.response.data)[0][0], {
                position: "top-right",
                theme: "dark"
            });
        })
    };

    useEffect(() => {
        return () => {
            reset();
            if (initialRender.current) {
                initialRender.current = false;
            } else {
                setImageToCrop(eventProgramDetails?.photo);
            }
        };
    }, [handleClose]);

    return (
        <>
            <form
                autoComplete="off"
                onSubmit={handleSubmit(mySubmit)}
                style={{ marginTop: '1rem' }}
            >
                <Stack spacing={3}>
                    {editProgramForm.data.map((item, index) => (
                        <React.Fragment key={index}>
                            {
                                (eventData?.multiple_venues || !programFieldsToHide.includes(item.label)) //To hide fields based on multiple venues of event
                                    ? <RenderFormFields
                                        key={index}
                                        item={item}
                                        watch={watch}
                                        eventData={eventData}
                                        register={register}
                                        errors={errors}
                                        control={control}
                                        editForm={true}
                                        cardDetails={eventProgramDetails}
                                        file={imageToCrop}
                                        setFile={setImageToCrop}
                                        onImageChoose={onImageChoose}
                                    />
                                    : null
                            }
                        </React.Fragment>
                    ))}
                </Stack>

                {cropDialog ?
                    <DialogBox open={true} handleClose={() => handleDialogClose()}
                        title='Crop Itinerary Image'
                        content={
                            <CropImage
                                aspectType='1'
                                imageToCrop={imageToCrop}
                                uploadImage={setFormImage}
                                handleDialogClose={handleDialogClose}
                            />
                        }
                    />
                    : null
                }

                <DialogActions sx={{ my: 1, pr: 0, pt: 1 }}>
                    <Button variant="outlined" color='warning' onClick={() => reset()}>
                        Reset
                    </Button>
                    <Button type="submit" color='warning' variant="contained" sx={{
                        boxShadow: 'none'
                    }}>
                        Save Changes
                    </Button>
                </DialogActions>
            </form>
        </>
    )
}

const EditProgramDialog = ({ open, handleClose, setItems, eventProgramDetails }) => {
    return (
        <DialogBox open={open} handleClose={handleClose}
            title='Edit Itinerary' content={EditProgramContent(handleClose, setItems, eventProgramDetails)}
        />
    )
};

export default EditProgramDialog;
