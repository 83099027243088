import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from '@mui/lab';
import { Box, DialogContent, FormControl, FormControlLabel, IconButton, Radio, RadioGroup, Stack, TextField, Typography } from "@mui/material";
import pluralize from 'pluralize';
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { checkInGuest, getScanningReports } from '../../../../../../services/wEvents/eventGuests';
import { ALL_PERMISSION_ROLES } from "../../../../../../utils/constants/roles";
import { ticketCurrencies } from '../../../../../../utils/constants/ticketCurrency';
import { parseDecimalNumber } from '../../../../../../utils/functions/getFormattedNumber';
import { getOrdinal } from '../../../../../../utils/functions/getOrdinal';
import QrGenerator from './QrGenerator';
import QrScanner from './QrScanner';
import SelfCheckInSuccessful from './SelfCheckInSuccessful';
import { getEventTickets } from '../../../../../../services/wEvents/eventDetails';

const EventQrCodeDetails = ({ handleClose, eventDetailsObj, scanPermission, guestData, setGuestData, maximumValueObj, ticketDetails, checkInDetails, handleNextClick, setScanningReports }) => {
    const [loader, setLoader] = useState({
        qrVerify: false,
        checkIn: false
    })
    const [maxValue, setMaxValue] = useState(0);
    const [ticketData, setTicketData] = useState(ticketDetails ?? null);
    const [checkInData, setCheckInData] = useState(checkInDetails ?? null);
    const [tickets, setTickets] = useState(null);
    const [ticketsCount, setTicketCount] = useState(1);
    const isSelfCheckIn = eventDetailsObj?.is_self_check_in;
    const isAllPermission = ALL_PERMISSION_ROLES.includes(eventDetailsObj?.current_user_role);
    const eventTicketType = eventDetailsObj?.is_paid ? 'paid' : 'free';
    const [checkInPurpose, setCheckInPurpose] = useState("CheckIn");
    const [maxValueObj, setMaxValueObj] = useState(maximumValueObj ?? null);

    const [successfulCheckInDialog, setCheckInDialog] = useState(false);

    useEffect(() => {
        getEventTickets({ eventId: eventDetailsObj?.id }).then((res) => {
            setTickets(res.data.data);
        }).catch((err) => {
            console.log(err);
        });
    }, []);
    
    useEffect(() => {
        setTicketCount(1);
        if (checkInPurpose === 'CheckIn') {
            setMaxValue(maxValueObj?.available_tickets || 0);
        } else {
            const facilityScanData = maxValueObj?.facility_availability_status.find(f => f.id === Number(checkInPurpose));
            setMaxValue(facilityScanData?.available_scans === null ? 2147483647 : facilityScanData?.available_scans);
        }
    }, [checkInPurpose, maxValueObj]);

    const handleScanningReportsClick = () => {
        handleNextClick();
        getScanningReports({ eventId: eventDetailsObj.id, guestId: guestData?.guest_id }).then((res) => {
            setScanningReports(res.data);
        }).catch((err) => {
            console.log(err);
            toast.error(err.response.data.error, {
                position: "top-right",
                theme: "dark"
            })
        })
    }

    const handleMinusTicketCount = () => {
        if (ticketsCount > 1) {
            setTicketCount((prev) => prev - 1);
        }
    }

    const handlePlusTicketCount = () => {
        if (ticketsCount < maxValue) {
            setTicketCount((prev) => prev + 1);
        }
    }

    const handleCheckInClose = (event, reason, operation) => {
        if (reason && reason === "backdropClick") // For not closing dialog on clicking outside of dialog
            return;
        document.body.classList.remove("no-scroll");
        setCheckInDialog(false);
        handleClose(null, null, 'qrCode');
    }

    const handleCheckInClick = () => {
        setLoader({ ...loader, checkIn: true });
        const payload = {
            'event_id': eventDetailsObj?.id,
            'guest_id': guestData?.guest_id, // Guest whose QR code verified successfully
            'ticket_id': (guestData?.ticket_id || ticketData?.id) ?? null,
            'check_in_count': ticketsCount
        }
        if (checkInPurpose !== "CheckIn") {
            payload["guest_facility_id"] = checkInPurpose;
        }
        checkInGuest(eventDetailsObj?.id, payload).then((result) => {
            setLoader({ ...loader, checkIn: false });
            if (eventDetailsObj.is_self_check_in) {
                setCheckInDialog(true);
                toast.success("Self Check-in Successful!!", {
                    position: "top-right",
                    theme: "dark"
                });
            } else {
                toast.success("Checked In!!", {
                    position: "top-right",
                    theme: "dark"
                });
                handleClose(null, null, 'qrCode');
            }
        }).catch((err) => {
            console.log(err);
            setLoader({ ...loader, checkIn: false });
            toast.error('Something went wrong, Try again!', {
                position: "top-right",
                theme: "dark"
            });
        });
    }
    // Accessing the file_questions_and_answers object
    const fileQuestionsAndAnswers = guestData?.file_questions_and_answers;

    // Extracting questionText and answer values
    const questionsAndAnswers = fileQuestionsAndAnswers ? 
        Object.entries(fileQuestionsAndAnswers).map(([key, item]) => ({
            questionText: item.questionText,
            answer: item.answer
        })) 
        : [];

    return (
        <>
            <DialogContent sx={{ p: { xs: '1rem', md: '1rem' } }} dividers>
                {
                    (isSelfCheckIn && (isAllPermission || scanPermission)) ?
                        <QrGenerator eventId={eventDetailsObj?.id} /> :
                        (isSelfCheckIn || (isAllPermission || scanPermission)) ?
                            <>
                                {
                                    guestData ?
                                        <Stack spacing={2}>
                                            <Stack spacing={1}>
                                            {guestData.profile_photo ? (
                                                            <>
                                                                <Box style={{display:"flex", alignItems:"center"}}>

                                                                <Typography style={{width:"110px"}}><b>Photo:</b>&nbsp;</Typography> 
                                                                <Box component="img" src={guestData?.profile_photo} alt="Profile Photo" sx={{ width: '100%', maxWidth: 200, ml:"90px" }} className='profile-photo'/>
                                                                </Box>
                                                            </>
                                                        ) : null}
                                                <Typography><b>Name:</b>&nbsp; {guestData.name}</Typography>
                                                {(guestData.generated_by_owner && !guestData.user_id) ?
                                                    <>
                                                        <Typography><b>Ticket Title:</b>&nbsp; {ticketData.title}</Typography>
                                                        {ticketData.description ?
                                                            <Typography><b>Ticket Description:</b>&nbsp; {ticketData.description}</Typography>
                                                            : null
                                                        }
                                                    </> :
                                                    <>
                                                        <Typography><b>Contact:</b>&nbsp; +{guestData.dialing_code} {guestData.mobile}</Typography>
                                                        {guestData.email ?
                                                            <Typography><b>Email:</b>&nbsp; {guestData.email}</Typography>
                                                            : null
                                                        }
                                                        
                                                        {
                                                            eventDetailsObj?.has_registration ?
                                                                <>
                                                                    {guestData.ticket_title ?
                                                                        <Typography><b>Ticket Title:</b>&nbsp; {guestData.ticket_title}</Typography>
                                                                        : null
                                                                    }
                                                                    {guestData.description ?
                                                                        <Typography><b>Ticket Description:</b>&nbsp; {guestData.ticket_description}</Typography>
                                                                        : null
                                                                    }
                                                                    {(eventTicketType === "free" && tickets?.length === 0) ?
                                                                        null :
                                                                        <Typography><b>Tickets {!guestData.invited_by ? "Purchased" : ''}:</b>&nbsp; {guestData.tickets_bought - guestData.tickets_distributed}</Typography>
                                                                    }
                                                                    {guestData.invited_by ?
                                                                        <Typography>
                                                                            <b>Invited By:</b>&nbsp; {guestData.invited_by}
                                                                        </Typography>
                                                                        : null
                                                                    }
                                                                </> :
                                                                <></>
                                                        }
                                                        {
                                                            // show additional details that are passed through send tickets feature via csv file
                                                            (guestData.generated_by_owner && guestData.extra_guest_details) ? (
                                                                Object.entries(JSON.parse(guestData.extra_guest_details)).map(([key, value]) => (
                                                                    <Typography key={key}>
                                                                        <b>{key}</b>: {value}
                                                                    </Typography>
                                                                ))
                                                            ) : null
                                                        }

                                                        {((!guestData.invited_by) && guestData.amount_to_pay>0) ?
                                                            <>
                                                                <Typography>
                                                                    <b>Amount Paid:</b>&nbsp; {parseDecimalNumber(guestData.amount_to_pay, 3)}&nbsp;
                                                                    <span dangerouslySetInnerHTML={{ __html: ticketCurrencies.find((t) => t.value === guestData.currency)?.symbol }} />
                                                                </Typography>
                                                            </>
                                                            : null
                                                        }
                                                        {checkInData?.map((cd, index) =>
                                                            <Typography key={index}>
                                                                <b>{getOrdinal(index + 1)} Check-in:</b>&nbsp; {cd} {pluralize('guest', cd)}
                                                            </Typography>
                                                        )}
                                                       {questionsAndAnswers.map((qa, index) => (
                                                           qa.answer ? (
                                                               <>
                                                                   <Box key={index} sx={{ mb: 2, display:"flex", alignItems:"center"}}>
                                                                       <Typography style={{width:"110px"}}><b>{qa.questionText}:</b></Typography>
                                                                       <Box component="img"
                                                                           src={qa.answer}
                                                                           alt={qa.questionText}
                                                                           sx={{ width: '100%', maxWidth: 200}}
                                                                           className='qa-answer'
                                                                       />
                                                                   </Box>
                                                               </>
                                                           ) : null
                                                       ))}
                                                    </>
                                                }
                                            </Stack>

                                            <Stack
                                                direction='row'
                                                alignItems='center' justifyContent='space-between'
                                                onClick={handleScanningReportsClick}
                                                sx={{
                                                    ":hover": {
                                                        cursor: 'pointer',
                                                    }
                                                }}>
                                                <Typography sx={{ fontSize: { xs: '1rem' } }}>
                                                    Scanning Reports
                                                </Typography>
                                                <Box sx={{ pr: 0.5 }}><ArrowForwardIosIcon sx={{ color: '#1F274A' }} /></Box>
                                            </Stack>

                                            {
                                                'facilities' in guestData ?
                                                    <FormControl>
                                                        <Typography sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>Scanning for</Typography>
                                                        <RadioGroup
                                                            row
                                                            value={checkInPurpose}
                                                            onChange={(e) => setCheckInPurpose(e.target.value)}
                                                        >
                                                            <FormControlLabel
                                                                value="CheckIn"
                                                                label="Check-In"
                                                                control={<Radio color="warning" />}
                                                                checked={(maxValueObj?.available_tickets === 0) || checkInPurpose === "CheckIn"}
                                                                disabled={maxValueObj?.available_tickets === 0}
                                                            />
                                                            {guestData?.facilities?.length > 0 && guestData?.facilities.map((facility, i) => {
                                                                const availableScans = maxValueObj?.facility_availability_status.find(f => f.id === facility.id)?.available_scans;
                                                                const isDisabled = !(maxValueObj?.available_tickets === 0 && (availableScans || availableScans === null));
                                                                return (
                                                                    <FormControlLabel
                                                                        key={i}
                                                                        value={facility.id}
                                                                        label={facility.name}
                                                                        control={<Radio color="warning" />}
                                                                        checked={isDisabled || (Number(checkInPurpose) === facility.id)}
                                                                        disabled={isDisabled}
                                                                    />
                                                            )})}
                                                        </RadioGroup>
                                                    </FormControl> :
                                                    null
                                            }

                                            {(((!guestData.generated_by_owner) || (guestData.generated_by_owner && guestData.user_id)) && maxValue > 0) ?
                                                <>
                                                    {
                                                        // Don't show number of guests option for normal free with no form & tickets & invite-only as it only allows 1 check-in
                                                        ((eventTicketType === "free" && tickets?.length === 0) || guestData?.facilities?.length > 0) ?
                                                            null :
                                                            <Stack direction='row' spacing={1} alignItems='center'>
                                                                <Typography>
                                                                    <b>Number of guests:</b>&nbsp;
                                                                </Typography>
                                                                <Stack direction='row' spacing={1} alignItems='center'>
                                                                    <IconButton onClick={handleMinusTicketCount}>
                                                                        <RemoveCircleOutlineIcon color='warning' />
                                                                    </IconButton>
                                                                    <TextField
                                                                        color="warning"
                                                                        sx={{ width: '4rem' }}
                                                                        inputProps={{
                                                                            type: 'number',
                                                                            min: 0,
                                                                            max: maxValue
                                                                        }}
                                                                        value={ticketsCount}
                                                                        onChange={(e) => {
                                                                            if (e.target.value < maxValue + 1) {
                                                                                setTicketCount(Number(e.target.value))
                                                                            }
                                                                        }}
                                                                    />
                                                                    <IconButton onClick={handlePlusTicketCount}>
                                                                        <AddCircleOutlineIcon color='warning' />
                                                                    </IconButton>
                                                                </Stack>
                                                            </Stack>
                                                    }
                                                </>
                                                : <></>
                                            }
                                            <Box sx={{ textAlign: 'center' }}>
                                                <LoadingButton
                                                    type="submit"
                                                    color="warning"
                                                    variant="contained"
                                                    onClick={handleCheckInClick}
                                                    loading={loader.checkIn ? true : false}
                                                    disabled={maxValue === 0 ? true : false}
                                                    startIcon={loader.checkIn ? <SaveIcon /> : null}
                                                    loadingPosition={loader.checkIn ? "start" : null}
                                                >
                                                    CheckIn
                                                </LoadingButton>
                                            </Box>
                                        </Stack> :
                                        <QrScanner
                                            loader={loader}
                                            setLoader={setLoader}
                                            setMaxValueObj={setMaxValueObj}
                                            setGuestData={setGuestData}
                                            setTicketData={setTicketData}
                                            setCheckInData={setCheckInData}
                                            eventDetailsObj={eventDetailsObj}
                                            selfCheckIn={isSelfCheckIn}
                                        />
                                }
                            </>
                            :
                            <QrGenerator guestUuid={eventDetailsObj?.guest_uuid} />
                }


            </DialogContent>
            {successfulCheckInDialog ?
                <SelfCheckInSuccessful
                    checkInData={checkInData}
                    eventDetailsObj={eventDetailsObj}
                    guestData={guestData}
                    handleCheckInClose={handleCheckInClose}
                    tickets={tickets}
                    eventTicketType={eventTicketType}
                />
                : null
            }
        </>
    )
};

export default EventQrCodeDetails;