import EditIcon from '@mui/icons-material/Edit';
import {
    FormControl, FormControlLabel, IconButton, Radio, RadioGroup,
    Stack, Switch, TextField, Typography, Box
} from "@mui/material";
import React, { useState } from "react";
import { useForm } from "react-hook-form"; // Import useForm from react-hook-form
import UploadImage from '../../../../../../../../../src/components/UploadImage'; // Ensure the path to your UploadImage component is correct

const RFormView = ({ formTitle, formDetails, handleEditClick }) => {
    const { register, formState: { errors } } = useForm(); // Initialize useForm with formState for errors

    // State to manage selected images and deletion status
    const [guestImage, setGuestImage] = useState(null);
    const [isDeletePhoto, setIsDeletePhoto] = useState(false);

    const renderItem = (item) => {
        switch (item.type) {
            case 'text':
                return (
                    <>
                        <TextField
                            fullWidth
                            size="small"
                            color="warning"
                            label={item.question}
                            inputProps={{ readOnly: true }}
                            required={Boolean(item.mandatory)}
                        />
                    </>
                )
            case 'textarea':
                return (
                    <>
                        <TextField
                            fullWidth
                            multiline
                            rows={3}
                            size="small"
                            color="warning"
                            label={item.question}
                            inputProps={{ readOnly: true }}
                            required={Boolean(item.mandatory)}
                        />
                    </>
                );
            case 'switch':
                return (
                    <>
                        <Stack direction='row' alignItems='center' justifyContent='space-between' spacing={2}>
                            <Typography sx={{ fontSize: { xs: '1rem' } }}>
                                {item.question}
                            </Typography>
                            <Switch
                                color='warning'
                            />
                        </Stack>
                    </>
                )
            case 'radio':
                return (
                    <>
                        <FormControl>
                            <Typography sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>{item.question}</Typography>
                            <RadioGroup row>
                                {item.options.map((option, i) => (
                                    <FormControlLabel key={i} value={option} control={<Radio color="warning" />} label={option} />
                                ))}
                            </RadioGroup>
                        </FormControl>
                    </>
                );
            case 'file':
                return (
                    <> 
                    <Box
                        sx={{
                            backgroundColor: item.question && !isDeletePhoto ? "white" : "#171717",
                            borderRadius: "10px",
                            width: { xs: "60%", sm: "50%", lg: "40%" },
                            height: "10rem",
                            position: "relative",
                            border: errors[item.question] ? '1px solid red' : '1px solid lightgrey',
                        }}
                    >
                        <UploadImage
                            fieldName={item.question}
                            label={item.question}
                            register={register} // Pass register to UploadImage
                            errors={errors} // Pass errors to UploadImage
                            selectedImage={isDeletePhoto ? null : guestImage}
                            setSelectedImage={(v) => {
                                setGuestImage(v);
                                if (isDeletePhoto) {
                                    setIsDeletePhoto(false);
                                }
                            }}
                            isDeletable={true}
                            onDelete={() => {
                                setIsDeletePhoto(true);
                                setGuestImage(null);
                            }}
                            disabled={true} 
                        />
                    </Box>
                    </>
                );
            default:
                return (
                    <>
                        <TextField
                            fullWidth
                            size="small"
                            color="warning"
                            label={item.question}
                            inputProps={{ readOnly: true }}
                            required={Boolean(item.mandatory)}
                        />
                    </>
                );
        }
    }

    return (
        <>
            <Stack spacing={2} sx={{
                width: '100%',
                borderRadius: '0.5rem',
                boxShadow: '0 0 2px 2px rgba(0, 0, 50, 0.1)',
                p: { xs: '0.8rem 0.5rem', sm: '0.8rem 0.75rem' },
                overflow: 'auto',
            }}>
                <Stack direction='row' spacing={1} alignItems='center' justifyContent='space-between'>
                    {formTitle ?
                        <Typography sx={{ fontWeight: 'bold', fontSize: '17px' }}>{formTitle}</Typography> :
                        <Typography sx={{ fontSize: '1.2rem' }}>Form Details</Typography>
                    }
                    <IconButton onClick={handleEditClick}>
                        <EditIcon sx={{ color: '#1F274A', fontSize: '1.3rem' }} />
                    </IconButton>
                </Stack>
                <Stack>
                    <Stack spacing={2} sx={{ pb: '0.5rem' }}>
                        {formDetails?.map((item, i) => (
                            <React.Fragment key={i}>
                                {renderItem(item)}
                            </React.Fragment>
                        ))}
                    </Stack>
                </Stack>
            </Stack>
        </>
    )
};

export default RFormView;
