import { Button, DialogActions, Stack } from "@mui/material";
import pluralize from "pluralize";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { EventProgramContext } from "..";
import CropImage from "../../../../../../../../../components/CropImage";
import DialogBox from "../../../../../../../../../components/DialogBox";
import RenderFormFields from "../../../../../../../../../components/RenderFormFields";
import { eventDetails } from "../../../../../../../../../redux/slices/eventDataSlice";
import { createEventProgram } from "../../../../../../../../../services/wEvents/eventDetails";
import { eventProgramForm } from "../../../../../../../../../utils/constants/createEventProgram";
import { manageEventProgramPayload } from "../../../../../../../../../utils/functions/managePayload";
import { programFieldsToHide } from "../../../../../../../../../utils/constants/eventProgramHideFields";

const CreateProgramContent = (handleClose, setItems) => {
    const {
        handleSubmit,
        register,
        formState: { errors },
        control, reset, setValue, watch
    } = useForm();

    const eventData = useSelector(eventDetails);
    const { viewId, title } = useContext(EventProgramContext);
    const [cropDialog, setCropDialog] = useState(false);
    const handleDialogOpen = () => {
        setCropDialog(true);
    }
    const handleDialogClose = () => {
        setCropDialog(false);
        setImageToCrop(null);
    }

    const [imageToCrop, setImageToCrop] = useState(null);
    const onImageChoose = (img) => {
        setImageToCrop(img);
        handleDialogOpen('crop');
    }
    const setFormImage = (croppedImage) => {
        handleDialogClose('crop');
        setImageToCrop(croppedImage);
        setValue('file', croppedImage);
    }

    const mySubmit = (data) => {
        if (data.end_datetime && data.start_datetime >= data.end_datetime) {
            toast.error('Start date must be less than end date', {
                position: "top-right",
                theme: "dark"
            });
            return;
        }
        data.viewId = viewId
        let payload = manageEventProgramPayload(data, eventData);
        createEventProgram(payload, eventData?.id).then((res) => {
            console.log('---------------->', res.data);
            toast.success(`${pluralize.singular(title)} Created Successfully!!`, {
                position: "top-right",
                theme: "dark"
            });
            setItems((items) => [...items, res.data.data])
            handleClose();
        }).catch((err) => {
            console.log(err);
            toast.error(Object.values(err.response.data)[0][0], {
                position: "top-right",
                theme: "dark"
            });
        })
    };

    useEffect(() => {
        return () => {
            reset();
            setImageToCrop(null);
        };
    }, [handleClose]);

    return (
        <>
            <form
                autoComplete="off"
                onSubmit={handleSubmit(mySubmit)}
                style={{ marginTop: '1rem' }}
            >
                <Stack spacing={3}>
                    {eventProgramForm.data.map((item, index) => (
                        <React.Fragment key={index}>
                            {
                                (eventData?.multiple_venues || !programFieldsToHide.includes(item.label)) //To hide fields based on multiple venues of event
                                    ? <RenderFormFields
                                        item={item}
                                        watch={watch}
                                        file={imageToCrop}
                                        setFile={setImageToCrop}
                                        eventData={eventData}
                                        register={register}
                                        errors={errors}
                                        control={control}
                                        onImageChoose={onImageChoose}
                                    />
                                    : null
                            }
                        </React.Fragment>
                    ))}
                    {cropDialog ?
                        <DialogBox open={true} handleClose={() => handleDialogClose('crop')}
                            title='Crop Event Logo'
                            content={
                                <CropImage
                                    aspectType='1'
                                    imageToCrop={imageToCrop}
                                    uploadImage={setFormImage}
                                    handleDialogClose={handleDialogClose}
                                />
                            }
                        />
                        : null
                    }
                </Stack>

                <DialogActions sx={{ my: 1, pr: 0, pt: 1 }}>
                    <Button variant="outlined" color='warning' onClick={() => reset()}>
                        Reset
                    </Button>
                    <Button type="submit" color='warning' variant="contained" sx={{
                        boxShadow: 'none'
                    }}>
                        Save Changes
                    </Button>
                </DialogActions>
            </form>
        </>
    )
}

const CreateProgramDialog = ({ handleClose, setItems }) => {
    return (
        <DialogBox open handleClose={handleClose}
            title='Add Itinerary' content={CreateProgramContent(handleClose, setItems)}
        />
    )
};

export default CreateProgramDialog;
