import { Button, DialogActions, Stack, Typography } from "@mui/material";
import pluralize from "pluralize";
import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { EventProgramContext } from "..";
import DialogBox from "../../../../../../../../../components/DialogBox";
import { eventDetails } from "../../../../../../../../../redux/slices/eventDataSlice";
import { deleteEventProgram } from "../../../../../../../../../services/wEvents/eventDetails";

const DeleteProgramContent = (handleClose, setItems, eventProgramDetails) => {
    const {
        handleSubmit,
    } = useForm();
    const eventData = useSelector(eventDetails);
    const { title } = useContext(EventProgramContext);

    const mySubmit = () => {
        deleteEventProgram(eventData?.id, eventProgramDetails?.id).then(() => {
            setItems((items) => {
                let newItems = items.filter((i) => i.id !== eventProgramDetails?.id)
                return newItems;
            })
            toast.success(`${pluralize.singular(title)} Deleted!!`, {
                position: "top-right",
                theme: "dark"
            });
            handleClose();
        }).catch((err) => {
            console.log(err);
        })
    };

    return (
        <>
            <form onSubmit={handleSubmit(mySubmit)} >
                <Stack spacing={3}>
                    <Typography>Are you sure you want to delete this Itinerary details ?</Typography>
                    <DialogActions sx={{ my: 1, pr: 0, pt: 1 }}>
                        <Button variant="outlined" color='warning' onClick={handleClose}>
                            Cancel
                        </Button>
                        <Button type="submit" color='warning' variant="contained" sx={{
                            boxShadow: 'none'
                        }}>
                            Ok
                        </Button>
                    </DialogActions>
                </Stack>
            </form>
        </>
    )
}

const DeleteProgramDialog = ({ open, handleClose, setItems, eventProgramDetails }) => {
    return (
        <DialogBox open={open} handleClose={handleClose}
            title='Delete Itinerary' content={DeleteProgramContent(handleClose, setItems, eventProgramDetails)}
        />
    )
};

export default DeleteProgramDialog;
