import { Box, Button, Checkbox, Stack, Table, TableBody, TableCell, TableContainer, TableRow, Typography, useTheme } from "@mui/material";
import React, { useContext, useState } from "react";
import { useSelector } from "react-redux";
import { GuestRegistrationContext } from "../..";
import TicketPaymentIntegration from "./TicketPaymentIntegration";
import { eventDetails } from "../../../../../../../../redux/slices/eventDataSlice";
import { ticketCurrencies } from "../../../../../../../../utils/constants/ticketCurrency";
import { parseDecimalNumber } from "../../../../../../../../utils/functions/getFormattedNumber";
import CashPaymentIntegration from "./CashPaymentIntegration";
import CustomRadioGroup from "../../../../../../../../components/CustomRadioGroup";
import { PAYMENT_MODES } from "../../../../../../../../utils/constants/paymentModes";

const GuestTicketPayment = () => {
    const theme = useTheme();
    const eventData = useSelector(eventDetails);
    const { registrationData, paymentDone, paymentDetails, handleSlideChange } = useContext(GuestRegistrationContext);
    const [isWhatsappChecked, setIsWhatsappChecked] = useState(1);
    const [paymentMode, setPaymentMode] = useState(!(eventData?.current_user_role !== null && ['owner', 'manager'].includes(eventData?.current_user_role)) ? PAYMENT_MODES.ONLINE : PAYMENT_MODES.CASH)
    const total_ticket_amount = paymentDetails.total_ticket_amount;
    const total_facility_amount = paymentDetails.total_facility_amount;
    const total_amount = paymentDetails.total_amount_to_pay;
    
    const selectedFacilities = registrationData.facility_status.filter(facility => facility.selected);
    const facilityNames = selectedFacilities.map(facility => facility.name);

    const whatsAppCheck = () => {
        setIsWhatsappChecked(Number(!isWhatsappChecked));
    }

    const handlePaymentModeChange = (event) => {
        setPaymentMode(event.target.value);
    };

    const rows = [
        {
            id: 1,
            title: "Attendee Name",
            value: paymentDetails.guest_name,
        },
        {
            id: 2,
            title: "Attendee Mobile Number",
            value: `+${paymentDetails.guest_dial_code} ${paymentDetails.guest_number}`,
        },
        {
            id: 3,
            title: "Attendee Email",
            value: paymentDetails.guest_email,
        },
        {
            id: 4,
            title: "Event Name",
            value: eventData?.title,
        },
    ]
    return (
        <>
            <TableContainer sx={{
                border: '1px solid lightgrey',
                maxWidth: { xs: '90%', md: '80%', lg: '70%' }, margin: '1rem auto !important'
            }}>
                <Table stickyHeader>
                    <TableBody>
                        {rows.map((row) => (
                            <TableRow hover={true} key={row.id}>
                                <TableCell sx={{
                                    px: '3rem',
                                    width: '4rem',
                                    textAlign: 'center',
                                    borderRight: '1px solid #ccc'
                                }}>{row.title}
                                </TableCell>
                                <TableCell sx={{
                                    width: '3rem',
                                    textAlign: 'center'
                                }} align="center">{row.value}</TableCell>
                            </TableRow>
                        ))}

                        {paymentDetails?.selected_tickets.map((item, index) => (
                            <TableRow
                                hover={true}
                                key={index}
                            >
                                <TableCell sx={{
                                    fontWeight: 'bold', borderRight: '1px solid #ccc',
                                    px: 0, textAlign: 'center', width: '4rem'
                                }}>
                                    <Stack direction={{ xs: 'column', md: 'row' }}
                                        spacing={{ xs: 1, lg: 0 }} alignItems='center' justifyContent='space-around'>
                                        <Typography>
                                            {item.ticket_name}<br />({item.ticket_count})
                                        </Typography>
                                        <Typography>
                                            x
                                        </Typography>
                                        <Typography>
                                            Per Ticket Price<br />
                                            ({parseDecimalNumber(item.ticket_price, 3)} <span dangerouslySetInnerHTML={{ __html: paymentDetails.ticket_currency_symbol }} />)
                                        </Typography>
                                    </Stack>
                                </TableCell>
                                <TableCell align="center" sx={{
                                    fontWeight: 'bold', px: 0, textAlign: 'center', width: '3rem'
                                }}>
                                    Total Ticket Amount <br />({item.ticket_count * parseDecimalNumber(parseDecimalNumber(item.ticket_price, 3), 3)} <span dangerouslySetInnerHTML={{ __html: paymentDetails.ticket_currency_symbol }} />)
                                </TableCell>
                            </TableRow>
                        ))}
                        
                        {
                            facilityNames.length > 0 &&
                            <TableRow hover={true}>
                                <TableCell sx={{
                                    px: '3rem',
                                    width: '4rem',
                                    textAlign: 'center',
                                    borderRight: '1px solid #ccc'
                                }}>
                                    <Typography>
                                        {facilityNames.length > 1 ? facilityNames.join(', ') : facilityNames[0]}
                                    </Typography>
                                </TableCell>
                                <TableCell align="center" sx={{
                                    fontWeight: 'bold', px: 0, textAlign: 'center', width: '3rem'
                                }}>
                                    {parseDecimalNumber(total_facility_amount, 3)} <span dangerouslySetInnerHTML={{ __html: paymentDetails.ticket_currency_symbol }} />
                                </TableCell>
                            </TableRow>
                        }

                        <TableRow hover={true}>
                            <TableCell sx={{
                                color: '#1F274A', fontSize: '1.2rem',
                                fontWeight: 'bold', borderRight: '1px solid #ccc', textAlign: 'center'
                            }}>
                                Amount to pay
                            </TableCell>
                            <TableCell align="center" sx={{ color: '#FF8359', fontSize: '1.2rem', fontWeight: 'bold', textAlign: 'center' }}>
                                {parseDecimalNumber(isWhatsappChecked ? total_amount + 2 : total_amount, 3)} <span dangerouslySetInnerHTML={{ __html: paymentDetails.ticket_currency_symbol }} />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <Box sx={{ textAlign: 'right', display: 'flex', justifyContent: 'end', alignItems: 'center' }} className="whatsapp-ticket">
                <Typography sx={{ fontSize: { xs: "1rem" } }}>
                    Do you want ticket on WhatsApp?
                </Typography>
                <Checkbox sx={{ paddingRight: 0 }} color='warning' checked={isWhatsappChecked ? true : false} onChange={(e) => {whatsAppCheck()}} />
            </Box>
            {
                (eventData?.current_user_role !== null && ['owner', 'manager'].includes(eventData?.current_user_role)) ?
                    <Box sx={{ maxWidth: { xs: '90%', md: '80%', lg: '70%' }, margin: '1rem auto !important' }}>
                        <Typography sx={{ mb: 1 }}>Payment Mode</Typography>
                        <CustomRadioGroup value={paymentMode} handleRadioChange={handlePaymentModeChange}
                            option1={PAYMENT_MODES.CASH} option2={PAYMENT_MODES.ONLINE} />
                    </Box> :
                    null
            }
            {
                paymentMode === PAYMENT_MODES.ONLINE ? 
                    <Typography sx={{
                        mt: 1, textAlign: 'center',
                        color: '#6F738B', fontSize: '0.8rem'
                    }}>
                        <span style={{
                            color: theme.palette.primaryColor
                        }}>Note:</span> Minimum 1 Rs. payment will be taken as per razorpay standards!!
                    </Typography> : 
                    null
            }
            <Stack spacing={2} direction='row' justifyContent='center'
                sx={{ textAlign: 'center', my: 2 }}>
                {!paymentDone ?
                    <Button variant="outlined"
                        onClick={() => handleSlideChange(1)}>Go to previous step</Button>
                    : null
                }
                    <Box sx={{ textAlign: 'center' }}>
                    {
                        paymentMode === PAYMENT_MODES.ONLINE ? 
                            <TicketPaymentIntegration
                                amountCurrencyForRazorPay={ticketCurrencies.find((t) => t.value === paymentDetails.amount_currency).currency}
                                totalAmountToPay={total_amount < 1 ? isWhatsappChecked ? 3 : 1 : isWhatsappChecked ? total_amount + 2 : total_amount}
                                sendToWhatsapp={isWhatsappChecked}
                            /> :
                            <CashPaymentIntegration
                                amountCurrency={ticketCurrencies.find((t) => t.value === paymentDetails.amount_currency).currency}
                                totalAmountToPay={total_amount < 1 ? isWhatsappChecked ? 3 : 1 : isWhatsappChecked ? total_amount + 2 : total_amount}
                                sendToWhatsapp={isWhatsappChecked}
                            />
                    }
                </Box>
            </Stack>
        </>
    )
};

export default GuestTicketPayment;
