import { Box, Button, DialogActions, MenuItem, Select, Stack, TextField } from "@mui/material";
import _ from "lodash";
import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { EventGuestsContext } from "../../EventGuestContext";
import { eventDetails } from "../../../../../../../../redux/slices/eventDataSlice";
import { editEventGuest } from "../../../../../../../../services/wEvents/eventGuests";
import { codes as countryCodes } from '../../../../../../../../utils/constants/countries.codes';
import { manageEventGuestPayload } from "../../../../../../../../utils/functions/managePayload";

const EditEventGuest = ({ guestDetails, setEdit }) => {
    const { setGuests } = useContext(EventGuestsContext)
    const {
        handleSubmit,
        register,
        formState: { errors },
        reset
    } = useForm();

    const eventData = useSelector(eventDetails)

    const mySubmit = (data) => {
        let payload = manageEventGuestPayload(data, true)
        editEventGuest(payload, eventData?.id, guestDetails.id).then((res) => {
            console.log('---------------->', res.data);
            toast.success("Event guest updated successfully!!", {
                position: "top-right",
                theme: "dark"
            });
            setGuests((guests) => {
                let ele = guests.find(i => i.id === res.data?.data?.id);
                guests[guests.indexOf(ele)] = res.data.data;
                return [...guests];
            })
            setEdit(false);
        }).catch((err) => {
            console.log(err);
            toast.error(Object.values(err.response.data)[0][0], {
                position: "top-right",
                theme: "dark"
            });
        })
    };

    return (
        <>
            <form
                autoComplete="off"
                onSubmit={handleSubmit(mySubmit)}
                style={{ marginTop: '1rem' }}
            >
                <Stack spacing={3}>
                    <TextField
                        fullWidth
                        color="warning"
                        label="Enter Name"
                        name="guest_name"
                        inputProps={{ type: 'text' }}
                        defaultValue={_.get(guestDetails, 'name')}
                        error={_.get(errors, 'guest_name') ? true : false}
                        helperText={
                            _.get(errors, 'guest_name') ? 'This is required field' : null
                        }
                        {...register('guest_name', { required: true })}
                    />

                    <TextField
                        fullWidth
                        color="warning"
                        label="Email ID"
                        name="email"
                        inputProps={{ type: 'email' }}
                        defaultValue={_.get(guestDetails, 'email')}
                        error={_.get(errors, 'email') ? true : false}
                        helperText={
                            _.get(errors, 'email') ? 'This is required field' : null
                        }
                        {...register('email', { required: false })}
                    />

                    {
                        guestDetails?.extra_csv_details ? (
                            Object.entries(guestDetails.extra_csv_details).map(([key, value]) => (
                                <TextField
                                    fullWidth
                                    color="warning"
                                    label={key}
                                    name={key}
                                    inputProps={{ type: 'text' }}
                                    defaultValue={value}
                                    error={_.get(errors, key) ? true : false}
                                    helperText={
                                        _.get(errors, key) ? 'This is required field' : null
                                    }
                                    {...register(key, { required: false })}
                                />
                            ))
                        ) : null
                    }

                    {/* No need to update guest number as of now */}
                    {/* <Stack direction='row' spacing={1}>
                        <Select
                            color="warning"
                            defaultValue={_.get(guestDetails, 'dialing_code')}
                            error={_.get(errors, 'dialing_code') ? true : false}
                            {...register('dialing_code', { required: true })}
                            MenuProps={{
                                PaperProps: {
                                    sx: {
                                        maxHeight: "300px",
                                        width: "7rem",
                                        marginTop: "0.5rem",
                                        border: "1px solid #E4E4E4",
                                        p: 1.5,
                                        pt: 0.75,
                                        boxShadow: "0",
                                        borderRadius: "4px",
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: 2,
                                        scrollbarWidth: "0px",
                                        "&& .Mui-selected": {
                                            backgroundColor: "lightblue",
                                            border: "1px solid #E4E4E4",
                                            "&:hover": {
                                                backgroundColor: "#FAFBFD"
                                            }
                                        }
                                    }
                                }
                            }}
                        >
                            {countryCodes.map((code, index) => (
                                <MenuItem key={index} value={code.dial_code}>
                                    <Box>+{code.dial_code}</Box>
                                </MenuItem>
                            ))}
                        </Select>
                        <TextField
                            color="warning"
                            label='Phone Number'
                            defaultValue={_.get(guestDetails, 'mobile')}
                            inputProps={{ 
                                type: 'tel',
                                pattern: '[0-9]*'
                            }}
                            error={_.get(errors, 'mobile') ? true : false}
                            helperText={
                                _.get(errors, 'mobile') ? 'This is required field' : null
                            }
                            {...register('mobile', { required: true })}
                            sx={{ flex: 1 }}
                        />
                    </Stack> */}

                </Stack>

                <DialogActions sx={{ my: 1, pr: 0, pt: 1 }}>
                    <Button variant="outlined" color='warning' onClick={() => {
                        reset();
                        setEdit(false);
                    }}>
                        Cancel
                    </Button>
                    <Button type="submit" color='warning' variant="contained" sx={{
                        boxShadow: 'none'
                    }}>
                        Save changes
                    </Button>
                </DialogActions>
            </form>
        </>
    )
};

export default EditEventGuest;
